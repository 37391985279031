import axios from 'axios'

function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
  
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});
  
}

export async function getTokens(login, password) {
    const session_url = process.env.VUE_APP_API_URL + 'auth/student_auth'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            username: login,
            password: password
        }
    }

    const response = await axios(config)

    if (response.status == 200 && response.data.access_token && response.data.refresh_token) {
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
        return true
    } else {
        return false
    }
}

async function rewriteTokensRequest() {
    const session_url = process.env.VUE_APP_API_URL + 'auth/student_refresh'
    
    const config = {
        method: 'post',
        url: session_url,
        headers: { 'Authorization': 'Bearer '+ localStorage.getItem('refresh_token') }
    }

    let response = {}
    try {
        response = await axios(config)
    } catch (error) {
        response.status = 422
    }

    if (response.status != 200) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        // window.location.reload()
    } else if (response.status == 200) {
        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('refresh_token', response.data.refresh_token)
    }
}

async function sendApiRequest(axiosConfig) {
    axiosConfig.headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('access_token') 
    }
    let response = {}
    try {
        response = await axios(axiosConfig)
    } catch (error) {
        response.status = 422
    }

    if (response.status != 200) {
        await rewriteTokensRequest()
        axiosConfig.headers = {
            'Authorization': 'Bearer ' + localStorage.getItem('access_token') 
        }
        response = await axios(axiosConfig)
        return response
    } else if (response.status == 200) {
        return response
    }
}

export const checkStudentAuthData = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_student_by_auth_credentials'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequest(config)
}

export const getBalanceState = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'balance/get_balance_state'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const getGroupById = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_group_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerById = async (trainerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            trainer_id: trainerId
        }
    }

    return await sendApiRequest(config)
}

export const getPlanedTrainingsByGroupId = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_planed_trainings_by_group'

    groupId = groupId ? groupId : ''

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId,
            current_date: new Date().toISOString()
        }
    }

    return await sendApiRequest(config)
}

export const getCompletedTrainingsByGroupId = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_completed_trainings_by_group'

    groupId = groupId ? groupId : ''

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId,
        }
    }

    return await sendApiRequest(config)
}

export const getIncomesByBalanceId = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_all_virtual_incomes_by_balance_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const getOutlaysByBalanceId = async (balanceId, year) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_all_virtual_outlay_by_balance_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId,
            year: year
        }
    }

    return await sendApiRequest(config)
}

export const updateStudentPersonalDataAgreementsRequest = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/update_student_personal_data_agreement'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequest(config)
}

export const updateSchoolRulesAgreementsRequest = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/update_student_club_rules_agreement'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequest(config)
}

export const updateStudentRequest = async (student, passportPhotos, idPhotos, attorneyFiles, photoForPassport) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/update_student'
  
    const formData = new FormData()
  
    idPhotos?.forEach((id_photo, index) => {
        formData.append(`id_photos`, id_photo)
    })
  
    passportPhotos?.forEach((passport_photo, index) => {
        formData.append(`passport_photos`, passport_photo)
    })
  
    attorneyFiles?.forEach((attorney_file, index) => {
        formData.append(`attorney_files`, attorney_file)
    })
    
    photoForPassport?.forEach((photo_for_passport_file, index) => {
        formData.append(`photo_for_passport`, photo_for_passport_file)
    })

    formData.append('updated_student', JSON.stringify(student))
  
    const config = {
      method: 'put',
      url: session_url,
      data: formData
    }
  
    return await sendApiRequest(config)
}

export const deleteStudentFile = async (studentId, fileType, fileName) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/delete_student_document_photo'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            student_id: studentId,
            document_type: fileType,
            photo_name: fileName
        }
    }

    return await sendApiRequest(config)
}

export const checkBotSubscribe = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'alerts/telegram_bot_user_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequest(config)
}

export const getLocationById = async (locationId) => {
    const session_url = process.env.VUE_APP_API_URL + 'location/get_location_by_id'

    const config = { 
        method: 'get',
        url: session_url,
        params: {
            location_id: locationId
        }
    }

    return await sendApiRequest(config)
}

export const loadImageToDataURL = async (url) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function() {
            const reader = new FileReader();
            reader.onloadend = function() {
                resolve(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = function() {
            reject(new Error('Could not load image'));
        }
        xhr.open('GET', url);
        xhr.responseType = 'blob';

        xhr.send();
    });
}

export const createWayforpayTransaction = async (
    amount,
    student_id,
    student_name, 
    student_surname, 
    student_email = 'не задано',
    student_phone = 'не задано',
    tarriff_id = 0,
    tarriff_name = 0,
) => {
    const session_url = process.env.VUE_APP_API_URL + 'ipay/create_ipay_transaction'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            amount,
            student_id,
            student_name, 
            student_surname, 
            student_email,
            student_phone,
            tarriff_id,
            tarriff_name
        }
    }

    return await sendApiRequest(config)
}

export const sendPhotoToRemoveBg = async (blob) => {
    const formData = new FormData();
    formData.append('file', blob);
    
    const response = await axios(
        {
            url: 'https://standart_api.carpediem.pp.ua/subservice/removebg',
            method: 'POST',
            responseType: 'blob', 
            data: formData,
        }
    )

    return new Blob([response.data])
}

export const updateStudentReq = async (student_obj, photo, idPhotos, passportPhotos, attorneyFiles, photoForPassport) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/update_student'

    student_obj.birthday = new Date(student_obj.birthday).toISOString()
    student_obj.start_training = new Date(student_obj.start_training).toISOString()
    student_obj.passport_end_date = new Date(student_obj.passport_end_date).toISOString()

    idPhotos = idPhotos ?? []
    passportPhotos = passportPhotos ?? []
    attorneyFiles = attorneyFiles ?? []
    photoForPassport = photoForPassport ?? []

    const formData = new FormData()
    console.log('photo', typeof photo)
    if (photo !== null && photo.startsWith('data')) {
        formData.append('student_picture', dataURItoBlob(photo))
    } else {
        formData.append('student_picture', null)
    }

    idPhotos.forEach((id_photo, index) => {
        formData.append(`id_photos`, id_photo)
    })

    passportPhotos.forEach((passport_photo, index) => {
        formData.append(`passport_photos`, passport_photo)
    })

    attorneyFiles.forEach((attorney_file, index) => {
        formData.append(`attorney_files`, attorney_file)
    })

    photoForPassport.forEach((photo_for_passport_file, index) => {
        formData.append(`photo_for_passport`, photo_for_passport_file)
    })

    formData.append('updated_student', JSON.stringify(student_obj))
    console.log('updated student', student_obj)

    const config = {
        method: 'put',
        url: session_url,
        data: formData,
        params: {
            student_id: student_obj.id
        }
    }

    return await sendApiRequest(config)
}

export const getStudentsByGroupId = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_all_students_list_in_group'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getAllStudentMessages = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'messages/get_all_messages_by_receiver_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            receiver_id: studentId,
            date_from: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
            date_to: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
        }
    }

    return await sendApiRequest(config)
}

export const getStudentBodyParametersRequest = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'statistic/get_student_body_params_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId,
            date_from: new Date(new Date().getFullYear() - 1, 0, 1),
            date_to: new Date(new Date().getFullYear() + 1, 0, 1)
        }
    }

    console.log('config', config)

    return await sendApiRequest(config)
}

export const updateStudentPhoto = async (studentId, photo) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/update_student_photo'

    const data = new FormData()

    data.append('photo', dataURItoBlob(photo))

    const config = {
        method: 'put',
        url: session_url + `?student_id=${studentId}`,
        data: data
    }

    return await sendApiRequest(config)
}

export const getStudentCompletedTrainings = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_students_completed_trainings'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequest(config)
}

export const updateStudentWallpaper = async (studentId, wallpaper) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/update_student_wallpaper_photo'

    const data = new FormData()

    data.append('photo', dataURItoBlob(wallpaper))

    const config = {
        method: 'put',
        url: session_url + `?student_id=${studentId}`,
        data: data
    }

    return await sendApiRequest(config)
}

export const getCompletedTrainingsByStudentId = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_students_completed_trainings'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequest(config)
}

export const getAllRealIncomeTariffs = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_all_real_income_tariffs'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequest(config)
}