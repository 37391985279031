<template>
    <div style="background-color: rgba(29, 29, 29, .45);" class="w-[85%] h-full absolute z-10"></div>
    <div class="bg-[#F2E9E4] ml-[5vw] border-[1px] scale-125 border-[#252526] w-[214px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50 px-[16px] py-[16px] rounded-[10px] select-none">
        <div class="">
            <div>
                <div class="flex justify-center items-center">
                    <div class="border-[#8C8C8C] bg-[#474849] text-[#4A4E69] bg-opacity-20 border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px] ">
                        <div>
                            <img v-if="training.type == 'training'" src="../../assets/training_icon.svg">
                            <img v-else-if="training.type == 'gym'" src="../../assets/gym_icon.svg">
                            <img v-else-if="training.type == 'competition'" src="../../assets/competition_icon.svg">
                        </div>
                        <div 
                            class="text-center w-full relative text-[13px]"
                            :class="{
                                'left-[-10px]' : training.type
                            }"    
                        >
                            {{ training.type == 'training' ? 'Тренування' : training.type == 'gym' ? 'Зал' : training.type == 'competition' ? 'Змагання' : 'Не задано' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[11px]">
            <div>
                <div class="flex justify-center items-center">
                    <div class="border-[#8C8C8C] bg-[#474849] text-[#4A4E69] bg-opacity-20 text-[13px] border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">{{ changeDate.split('T')[0].split('-').reverse().join('.') }}</div>
                </div>
            </div>
        </div>

        <div class="mt-[11px]">
            <div class="flex justify-center items-center">
                <div class="border-[#8C8C8C] bg-[#474849] text-[#4A4E69] bg-opacity-20 text-[13px] border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">{{ trainingTime.start }}</div>
                <span class="font-bold mx-[13px] w-[30px] text-[#4A4E69]">
                    <img src="../../assets/divide_arrow.svg" alt="">
                </span>
                <div class="border-[#8C8C8C] bg-[#474849] text-[#4A4E69] bg-opacity-20 text-[13px] border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">{{ trainingTime.end }}</div>
            </div>
        </div>

        <div class="mt-[11px]">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="border-[#8C8C8C] bg-[#474849] text-[#4A4E69] bg-opacity-20 text-[13px] border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">{{ groupText }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[11px]">
            <div>

                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="border-[#8C8C8C] bg-[#474849] text-[#4A4E69] bg-opacity-20 text-[13px] border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">{{ locationText }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[11px]">
            <div>

                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="border-[#8C8C8C] bg-[#474849] text-[#4A4E69] bg-opacity-20 text-[13px] border-[1px] w-full flex py-[7px] pl-[7px] relative rounded-[4px] px-[4px] h-[124px] overflow-auto resize-y">
                            {{ location?.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[17px]">
            <div @click="openLocationUrl(location.geo_url)">
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="bg-[#3B9BF4] text-[#F2E9E4] px-3 outline-none cursor-pointer text-center placeholder-gray-500 rounded-[4px] text-[13px] py-[1.5px]">Відкрити на карті</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-[7px]">
            <div @click="$emit('close')">
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div class="bg-[#990D35] text-[#4A4E69]  px-3 outline-none cursor-pointer text-center placeholder-gray-500 rounded-[4px] text-[13px] py-[1.5px]">Закрити</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, computed, reactive, onBeforeMount } from 'vue'
    import { getLocationById, getGroupById } from '../../services/apiRequests'

    export default {
        name: 'full-training-view',

        props: {
            training: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const settedDate = new Date(props.training.date)
            const settedYear = settedDate.getFullYear() > 10 ? settedDate.getFullYear() : `0${settedDate.getFullYear()}`
            const settedMonth = settedDate.getMonth() + 1 > 10 ? settedDate.getMonth() + 1 : `0${settedDate.getMonth() + 1}`
            const settedDay = settedDate.getDate() > 10 ? settedDate.getDate() : `0${settedDate.getDate()}`
            const changeDate = ref(`${settedYear}-${settedMonth}-${settedDay}`)

            const group = ref(null)
            const groupText = ref(props.training.group_label)

            const location = ref(null)
            const locationText = ref(props.training.location_label)
            
            onBeforeMount(async () => {

                const currentGroupRes = await getGroupById(props.training.group_id)
                group.value = currentGroupRes.data

                const currentLocationRes = await getLocationById(props.training.location_id)
                location.value = currentLocationRes.data
            })

            const trainingTime = ref({
                start: props.training.start_time,
                end: props.training.end_time
            })

            const openLocationUrl = (url) => {
                window.open(url, '_blank')
            }
      

            return {
                settedDate,
                settedYear,
                settedMonth,
                settedDay,
                changeDate,
                group,
                groupText,
                trainingTime,
                locationText,
                location,
                openLocationUrl
            }
        }
    }
</script>
