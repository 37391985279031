<template>
    <!-- <div class="border-[1px] fixed bottom-[10px] right-[10px] px-3 py-2 border-green-500 z-[200] rounded-[10px] bg-green-500">
        Завантаження фото...
    </div>
    <div class="border-[1px] fixed bottom-[10px] right-[10px] px-3 py-2 border-red-500 z-[200] rounded-[10px] bg-red-500">
        Файл завеликий
    </div> -->
    <div class="mt-[20px]">
        <!-- <div class="text-[#4A4E69] flex justify-between mb-[20px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[15px] font-semibold">ДОКУМЕНТИ</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>

        <div class="flex items-center mt-5 justify-between px-[17px]">
            <button @click="$router.push('/agreement-personal-data')" class="px-[9.5px] border-[#4A4E69] text-gray-emb border-[1px] rounded-[9px]  py-2 w-[83%] text-[13px] flex items-center align-middle justify-between">
                <div class="w-full text-[13px] text-start relative top-[0.8px]">Договір про обробку персональних даних</div>
            </button>
            <IphoneSwitch :isOnValue="store.student.personal_data_agreement" />
        </div>
        <div class="flex items-center mt-5 justify-between px-[17px]">
            <button @click="$router.push('/school-rules')" class="px-[9.5px] border-[#4A4E69] text-gray-emb border-[1px] rounded-[9px]  py-2 w-[83%] text-[13px] flex items-center align-middle justify-between">
                <div class="w-full text-[13px] relative top-[0.8px] text-start">Правила та умови ФК Demo</div>
            </button>
            <IphoneSwitch :isOnValue="store.student.club_rules_agreement" />
        </div> -->

        <div class="flex items-center mt-5 justify-between px-[17px]">
            <button @click="$router.push('/agreement-personal-data')" class="px-[9.5px] border-[#4A4E69] text-gray-emb border-[1px] rounded-[9px]  py-2 w-[83%] text-[13px] flex items-center align-middle justify-between">
                <div class="w-full text-[13px] text-start relative top-[0.8px]">Договір про обробку персональних даних</div>
            </button>
            <IphoneSwitch :isOnValue="store.student.personal_data_agreement" />
        </div>

        <NotificationsBlock />

        <!-- <div class="flex items-center mt-[20px] mb-[17px] justify-center w-[90%] mx-auto">
            <div class="mr-[10px]">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16.0003" r="13.3333" stroke="#990D35"/>
                    <path d="M15.9883 20H16.0003" stroke="#990D35" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16 16L16 10.6667" stroke="#990D35" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="text-[#990D35] text-[12px]">
                Будь ласка, звантажте скан-копії документів грвавця. Документи будуть використовуватися виключно при формуванні анкет для участі у змаганнях.
            </div>
        </div>
        
        <div class="student-data-table mt-4 mx-4 border-[0.2px] border-[#A5A3A3] border-opacity-25 rounded-2xl px-6 py-1">
            <div class="flex text-gray-emb justify-between border-b-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                <div class="text-sm font-light">Паспорт</div>
                <div class="font-light text-sm flex">
                    <div class="mx-8 flex">
                        <div @click="$emit('showPassportFiles')" v-for="passportImage in store.student.passport_files" class="ml-1">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.02693 15.329C1.18385 16.277 2.0075 17 3 17H15C16.1046 17 17 16.1046 17 15V11.1901M1.02693 15.329C1.00922 15.222 1 15.1121 1 15V3C1 1.89543 1.89543 1 3 1H15C16.1046 1 17 1.89543 17 3V11.1901M1.02693 15.329L4.84762 11.5083C5.52765 10.9133 6.52219 10.8482 7.27403 11.3494L7.78316 11.6888C8.50784 12.1719 9.46185 12.1305 10.142 11.5865L12.7901 9.46793C13.4651 8.92795 14.4053 8.88557 15.1228 9.34842C15.2023 9.39972 15.2731 9.46325 15.34 9.53017L17 11.1901M8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6Z" stroke="#4A4E69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div @click="addPasportPhoto">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 10V15C17 16.1046 16.1046 17 15 17H3C1.89543 17 1 16.1046 1 15L1 10M13 5L9 1M9 1L5 5M9 1L9 13" stroke="#4A4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input 
                        @change="addPasportPhotoToArray($event.target.files[0])"
                        ref="pasportPhotoInput" 
                        type="file" 
                        class="hidden"
                    >
                </div>
            </div>
            <div class="flex text-gray-emb justify-between border-b-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-3">
                <div class="text-sm font-light">ID код</div>
                <div class="font-light text-sm flex">
                    <div class="mx-8 flex">
                        <div @click="$emit('showIdCodeFiles')" v-for="idCodeImage in store.student.id_code_files" class="ml-1">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.02693 15.329C1.18385 16.277 2.0075 17 3 17H15C16.1046 17 17 16.1046 17 15V11.1901M1.02693 15.329C1.00922 15.222 1 15.1121 1 15V3C1 1.89543 1.89543 1 3 1H15C16.1046 1 17 1.89543 17 3V11.1901M1.02693 15.329L4.84762 11.5083C5.52765 10.9133 6.52219 10.8482 7.27403 11.3494L7.78316 11.6888C8.50784 12.1719 9.46185 12.1305 10.142 11.5865L12.7901 9.46793C13.4651 8.92795 14.4053 8.88557 15.1228 9.34842C15.2023 9.39972 15.2731 9.46325 15.34 9.53017L17 11.1901M8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6Z" stroke="#4A4E69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div @click="addIdNumberPhoto">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 10V15C17 16.1046 16.1046 17 15 17H3C1.89543 17 1 16.1046 1 15L1 10M13 5L9 1M9 1L5 5M9 1L9 13" stroke="#4A4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input 
                        @change="addIdNumberPhotoToArray($event.target.files[0])"
                        ref="idNumberPhotoInput" 
                        type="file" 
                        class="hidden"
                    >
                </div>
            </div>
            <div class="flex text-gray-emb justify-between items-center py-3 border-b-[0.2px] border-[#A5A3A3] border-opacity-25">
                <div class="text-sm font-light">Довіреність на дитину</div>
                <div class="font-light text-sm flex">
                    <div class="mx-8 flex">
                        <div @click="$emit('showAttorneyFiles')" v-for="attorneyImage in store.student.attorney_child_files" class="ml-1">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.02693 15.329C1.18385 16.277 2.0075 17 3 17H15C16.1046 17 17 16.1046 17 15V11.1901M1.02693 15.329C1.00922 15.222 1 15.1121 1 15V3C1 1.89543 1.89543 1 3 1H15C16.1046 1 17 1.89543 17 3V11.1901M1.02693 15.329L4.84762 11.5083C5.52765 10.9133 6.52219 10.8482 7.27403 11.3494L7.78316 11.6888C8.50784 12.1719 9.46185 12.1305 10.142 11.5865L12.7901 9.46793C13.4651 8.92795 14.4053 8.88557 15.1228 9.34842C15.2023 9.39972 15.2731 9.46325 15.34 9.53017L17 11.1901M8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6Z" stroke="#4A4E69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div @click="addAttorneyPhoto">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 10V15C17 16.1046 16.1046 17 15 17H3C1.89543 17 1 16.1046 1 15L1 10M13 5L9 1M9 1L5 5M9 1L9 13" stroke="#4A4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input 
                        @change="addAttorneyPhotoToArray($event.target.files[0])"
                        ref="attorneyPhotoInput" 
                        type="file" 
                        class="hidden"
                    >
                </div>
            </div>

            <div class="flex text-gray-emb justify-between border-opacity-25 items-center py-3">
                <div class="text-sm font-light">Фото на паспорт</div>
                <div class="font-light text-sm flex">
                    <div class="mx-8 flex">
                        <div @click="$emit('showPhotosForPassport')" v-if="store.student.photo" class="ml-1">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.02693 15.329C1.18385 16.277 2.0075 17 3 17H15C16.1046 17 17 16.1046 17 15V11.1901M1.02693 15.329C1.00922 15.222 1 15.1121 1 15V3C1 1.89543 1.89543 1 3 1H15C16.1046 1 17 1.89543 17 3V11.1901M1.02693 15.329L4.84762 11.5083C5.52765 10.9133 6.52219 10.8482 7.27403 11.3494L7.78316 11.6888C8.50784 12.1719 9.46185 12.1305 10.142 11.5865L12.7901 9.46793C13.4651 8.92795 14.4053 8.88557 15.1228 9.34842C15.2023 9.39972 15.2731 9.46325 15.34 9.53017L17 11.1901M8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6Z" stroke="#4A4E69" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div @click="addPhotoForPassport">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 10V15C17 16.1046 16.1046 17 15 17H3C1.89543 17 1 16.1046 1 15L1 10M13 5L9 1M9 1L5 5M9 1L9 13" stroke="#4A4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input 
                        @change="addPhotoForPassportToArray($event.target.files[0])"
                        ref="photoForPassportInput" 
                        type="file"
                        class="hidden"
                    >
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    import IphoneSwitch from './IphoneSwitch.vue'
    import NotificationsBlock from './NotificationsBlock.vue'
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    import { updateStudentRequest } from '@/mobileApp/services/apiRequests'

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    function compressImage(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function (event) {
            const img = new Image();
            img.src = event.target.result;

            img.onload = function () {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Рассчитываем новые размеры изображения
                const maxWidth = 800; // Максимальная ширина
                const maxHeight = 800; // Максимальная высота
                let width = img.width;
                let height = img.height;

                if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
                } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
                }

                // Устанавливаем новые размеры canvas
                canvas.width = width;
                canvas.height = height;

                // Отрисовываем изображение на canvas с новыми размерами
                ctx.drawImage(img, 0, 0, width, height);

                // Получаем сжатое изображение в формате data URL
                const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.8); // Качество сжатия (от 0 до 1)

                // Преобразуем data URL обратно в объект File
                const byteString = atob(compressedDataUrl.split(',')[1]);
                const mimeString = compressedDataUrl.split(',')[0].split(':')[1].split(';')[0];
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);

                for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
                }

                const compressedFile = new File([ab], file.name, { type: mimeString });

                resolve(compressedFile);
            };

            img.onerror = function (error) {
                reject(error);
            };
            };

            reader.onerror = function (error) {
            reject(error);
            };

            reader.readAsDataURL(file);
        });
    }


    export default {
        name: 'documents-block',

        setup() {
            const store = useMainStore()
            const router = useRouter()
            const pasportPhotoInput = ref(null)
            const idNumberPhotoInput = ref(null)
            const attorneyPhotoInput = ref(null)
            const photoForPassportInput = ref(null)

            const imageToCrop = ref({
                src: '',
                type: ''
            })

            const addPasportPhoto = () => {
                pasportPhotoInput.value.click()
            }

            const addIdNumberPhoto = () => {
                idNumberPhotoInput.value.click()
            }

            const addAttorneyPhoto = () => {
                attorneyPhotoInput.value.click()
            }

            const addPhotoForPassport = () => {
                photoForPassportInput.value.click()
            }

            const addPhotoForPassportToArray = async (file) => {
                if (!store.student.photo_for_passport) {
                    store.student.photo_for_passport = []
                }
                const compressedFile = await compressImage(file)

                const blob = URL.createObjectURL(compressedFile)
                const reader = new FileReader()
                reader.onload = (e) => {
                    imageToCrop.value.src = blob
                    console.log('imageToCrop.value.src', blob)
                    imageToCrop.value.type = getMimeType(e.target.result, compressedFile.type)
                    store.imageToCrop = imageToCrop.value
                    router.push('/photo-loader')
                }
                reader.readAsArrayBuffer(compressedFile)
                
            }

            const addPasportPhotoToArray = async (file) => {
                if (!store.student.passport_files) {
                    store.student.passport_files = []
                }
                const compressedFile = await compressImage(file)
                store.student.passport_files.push(compressedFile)
                await updateStudent()
            }

            const addIdNumberPhotoToArray = async (file) => {
                if (!store.student.id_code_files) {
                    store.student.id_code_files = []
                }
                const compressedFile = await compressImage(file)
                store.student.id_code_files.push(compressedFile)
                await updateStudent()
            }

            const addAttorneyPhotoToArray = async (file) => {
                if (!store.student.attorney_child_files) {
                    store.student.attorney_child_files = []
                }
                const compressedFile = await compressImage(file)
                store.student.attorney_child_files.push(compressedFile)
                await updateStudent()
            }

            const updateStudent = async () => {
                await updateStudentRequest(store.student, store.student.passport_files, store.student.id_code_files, store.student.attorney_child_files, store.student.photo_for_passport)
                await store.getStudentData()
            }

            return {
                store,
                pasportPhotoInput,
                addPasportPhoto,
                addPasportPhotoToArray,
                addIdNumberPhotoToArray,
                addIdNumberPhoto,
                idNumberPhotoInput,
                addAttorneyPhoto,
                addAttorneyPhotoToArray,
                attorneyPhotoInput,
                addPhotoForPassport,
                addPhotoForPassportToArray,
                photoForPassportInput
            }
        },

        components: {
            IphoneSwitch,
            NotificationsBlock
        }
    }
</script>