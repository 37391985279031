<template>
    <div class="container-adaptive-text">
        <div class="subcontainer flex items-center jus h-full">
            
            <div class="mt-[40px] marquee-container">
                <div class="surname marquee font-black h-[100px] flex gap-[40px]">
                    <div v-for="z in 100">
                        <img src="../../assets/wall_pattern.svg" class="h-[120px]">
                        <span >{{ '&nbsp;&nbsp;' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onBeforeMount, watch } from 'vue'

    export default {
        name: 'AdaptiveTextBlockNew',

        props: {
            name: {
                type: String,
                required: true
            },
            surname: {
                type: String,
                required: true
            },
            number: {
                type: String,
                required: true
            }
        },

        setup(props, { emit }) {
        }
    }

</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Avenir Bold';
        src: url('../../assets/Avenir-bold.ttf');
    }

    @font-face {
        font-family: 'Avenir Regular';
        src: url('../../assets/Avenir-regular.ttf');
    }

    @font-face {
        font-family: 'Avenir LST Roman';
        src: url('../../assets/AvenirLTStd-Roman.otf');
    }

    @font-face {
        font-family: 'Fixel Regular';
        src: url('../../assets/FixelAll/FixelDisplay/FixelDisplay-Regular.ttf');
    }

    @font-face {
        font-family: 'Avenir LTS Medium';
        src: url('../../assets/AvenirLTStd-Medium.otf');
    }

    @font-face {
        font-family: 'Fixel SemiBold';
        src: url('../../assets/FixelAll/FixelDisplay/FixelDisplay-SemiBold.ttf');
    }

    .name {
        font-family: 'Fixel Regular';
        width: max-content;
        font-size: 20px;
        margin-left: 30px;
    }

    .empty-block {
        @apply h-[50px];
    }

    .marquee-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 125px;
    }

    .marquee {
        position: absolute;
        white-space: nowrap;
        height: max-content;
        animation: marquee 660s linear infinite;
    }

    .marquee > * {
        animation-delay: 25s;
    }

    @keyframes marquee {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    /* Стиль для Мака */
    @media screen and (-webkit-min-device-pixel-ratio: 2) {
        .surname {
            margin-top: 12px;
            line-height: 80%;
            font-size: 96px;
            font-weight: 400;
            width: max-content;
            color: #22223B;
            font-family: 'Fixel SemiBold';
        }
    }

    /* Стиль для Линукса */
    @media screen and (not (-webkit-min-device-pixel-ratio: 2)) {
        .surname {
            margin-top: 2px;
            line-height: 100%;
            font-size: 96px;
            font-weight: 900;
            width: max-content;
            color: #22223B;
            font-family: 'Avenir LST Roman';
        }
    }

    /* Стиль для Виндовс */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .surname {
            margin: 0;
            line-height: 80%;
            font-size: 96px;
            font-weight: 600;
            width: max-content;
            color: #22223B;
        }
    }

    @media (min-width: 0px) and (max-width: 1800px) {
        .name-number-block {
            margin-bottom: 3px;
        }

        .number {
            font-family: 'Avenir LST Roman';
            font-size: 20px;
            text-align: left;
            margin-right: 30px;
        }

        .subcontainer {
            @apply w-[100%] text-gray-emb;
        }
        
        .container-adaptive-text { 
            @apply flex justify-start;
        }

    }

    @media (min-width: 1800px) and (max-width: 8000px){
        .name-number-block {
            margin-bottom: 23px;
        }

        .container-adaptive-text { 
            @apply flex justify-center;
        }

        .name {
            position: relative;
            left: 27px;
        }

        .subcontainer {
            @apply w-full text-gray-emb;
        }

        .number {
            font-family: 'Avenir LST Roman';
            font-size: 20px;
            text-align: left;
            margin-right: 70px;
        }
    }

</style>
