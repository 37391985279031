<template>
    <div class="w-full bg-rose-emb h-[58px] z-10 absolute">
        <div class="flex justify-between mx-[25px] mt-[14px] items-center">
            <div @click="uploadPhoto" class="text-gray-emb mr-[10px] flex flex-col justify-between items-center text-center">
                <img src="../../assets/upload_icon.svg">
                <div class="text-gray-emb relative top-[3px]" style="font-size: 9px; font-weight: 400; line-height: 12px; letter-spacing: 0em; text-align: center;">фото</div>
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    :value="inputValue"
                    @change="loadImage($event)"
                />
            </div>
            <div class="text-gray-emb ml-[6px] top-[5px] relative w-[50.2%] text-center flex flex-col items-center justify-between">
                <div class="w-full h-[11px] rounded-[7px] bg-white relative bottom-[2px] border-[2px] border-gray-emb"></div>
                <div :style="['left: ' + trainingsRemainPercent + '%']" class="absolute top-[-8px] rounded-full bg-white h-[24px] w-[24px] border-gray-emb border-[2px]">
                    <div class="text-gray-emb text-center text-[14px] font-black relative top-[0px]">{{trainingsRemainCount}}</div>
                </div>
                <div class="text-[9px] mt-[5px]">доступно тренувань</div>
            </div>
            <div class="text-gray-emb text-center flex flex-col items-center justify-between">
                <img src="../../assets/bell_icon.svg" class="relative bottom-[1px]" @click="$router.push('/messages')">
                <div class="text-gray-emb relative top-[2px]" style="font-size: 9px; font-weight: 400; line-height: 12px; letter-spacing: 0em; text-align: center;">повідомлення</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, computed } from 'vue'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    import { useRouter } from 'vue-router'

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'general-information-bar',

        props: {
            trainerName: {
                type: String,
                default: ''
            },
            studentNumber: {
                type: String,
                default: ''
            },
            studentAmplua: {
                type: String,
                default: ''
            },
            studentAge: {
                type: Number,
                default: 0
            },
            studentGroup: {
                type: String,
                default: ''
            },
            studentName: {
                type: String,
                default: ''
            },
            tariffs: {
                type: Array,
                default: () => []
            },
            currentTariffId: {
                type: Number,
                default: 0
            }
        },

        setup(props, { emit }) {
            const store = useMainStore()
            const router = useRouter()

            const fileInput = ref(null)
            const inputValue = ref(null)

            const imageToCrop = ref({
                src: '',
                type: ''
            })

            const allTrainingsTariffsRemains = ref(0)
            const maxTariffsTrainings = ref(0)
            const trainingLineCountPercent = ref(0)

            onMounted(() => {
                allTrainingsTariffsRemains.value = props.tariffs.reduce((acc, item) => item ? acc + item.training_remains : acc, 0)
                maxTariffsTrainings.value = props.tariffs.reduce((acc, item) => item ? acc + item.tariff_training_count : acc, 0)
                trainingLineCountPercent.value = ((allTrainingsTariffsRemains.value / maxTariffsTrainings.value) * 100) - 5
            })
            
            const uploadPhoto = () => {
                fileInput.value.click()
            }

            const trainingsRemainCount = computed(() => {
                if (!props.tariffs[props.currentTariffId]) return 0
                return props.tariffs[props.currentTariffId].training_remains
            })

            const trainingsMaxCount = computed(() => {
                if (!props.tariffs[props.currentTariffId]) return 0
                return props.tariffs[props.currentTariffId].tariff_training_count
            })

            const trainingsRemainPercent = computed(() => {
                return (trainingsRemainCount.value / trainingsMaxCount.value) * 100 - 5
            })

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        console.log('imageToCrop.value.src', blob)
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        store.imageToCrop = imageToCrop.value
                        router.push('/wallpaper-loader')
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            return {
                fileInput,
                uploadPhoto,
                inputValue,
                loadImage,
                allTrainingsTariffsRemains,
                maxTariffsTrainings,
                trainingLineCountPercent,
                trainingsRemainCount,
                trainingsMaxCount,
                trainingsRemainPercent
            }
        }
    }
</script>
