<template>
    <div class="px-[3vw] flex pb-[30px]" v-if="loading">
        <div v-if="botAlertsView" @click="botAlertsView = false" class="bg-[#F2E9E4] opacity-50 h-[90vh] w-[83vw] absolute z-40"></div>
        <BotAlerts v-if="botAlertsView" @close="botAlertsView = false" class="absolute border-[1px] border-[#A1D9F7] rounded-[10px] ml-[100px] p-3 z-50 bg-[#F2E9E4] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"/>
        <Header 
            class="fixed top-[10px] left-[50%] translate-x-[-50%]"
            :class="{
                'ml-[122px] w-[80%]' : screenWidth > 1300,
                'ml-[30px] w-[85%]' : screenWidth < 1300,
            }"    
        />
        <div>
            <div class="flex mt-[87px] ml-[10px]">
                <NewInfoCard class="mr-[25px]"/>
                <div>
                    <NewParametersCard />
                    <NewDocumentsCard />
                </div>
            </div>
            <OtherSettingsBlock 
                @subscribeBot="botAlertsView = true"
                :botSubscribed="store.isBotSubscribed"
                :personalDataAgreement="store.student.personal_data_agreement"
                :clubRulesAgreement="store.student.club_rules_agreement"
                class="ml-[10px] mt-[25px]"
            />
        </div>
        <div class="mt-[90px] ml-[60px] border-l-[1px] border-[#4A4E69]">
            <div class="ml-[56px]">
                <button @click="updateStudent" class="bg-[#4A4E69] text-[#4A4E69] w-[250px] px-20 py-1 rounded-[6px]">
                    Зберегти
                </button>
                <div v-if="updateFlag" class="text-[#4A4E69] w-[250px] font-semibold py-1 rounded-[10px] text-end">
                    {{ updateText }}
                </div>
            </div>

            <div class="mt-[20px] flex h-[90%] pl-[10px] w-[310px] text-[#4A4E69] text-[13px] font-light">
                <div class="relative w-[170px]">
                    <img src="../assets/question_icon.svg" class="absolute top-[5px] left-[5px]">
                    <img src="../assets/attention_icon.svg" class="absolute top-[160px] left-[5px]">
                </div>
                <div>
                    <div class="mb-[20px]">
                        · для отримання динаміки, вносіть на регулярній основі дані про зріст та вагу
                    </div>
                    <div class="mb-[20px]">
                        · актуальні дані по розміру ноги, об’єму грудей та зросту, дають нам можливість оперативно робити закупкуспортиіної форми для наших спортсменів  
                    </div>
                    <div class="mb-[20px] text-[#990D35]">
                        інформацію про номер гравця, амплуа та старт в клубі вносить тренер або адміністратор клубу, тому ці поля не активні у кабінеті гравця
                    </div>
                    <div class="text-[#990D35]">
                        У випадку відсутності якоїсь з перелічених позицій, зверніться до свого тренера. 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#990D35] border-t-transparent absolute top-[50%] left-[50%] ml-[30px]"></div>
    </div>
</template>

<script>
    import { ref, onMounted, onBeforeMount } from 'vue'
    import { updateStudentReq } from '../services/apiRequests'
    import { useMainStore } from '../store/mainStore'
    import NewInfoCard from '../components/SettingsBlock/NewInfoCard.vue'
    import NewParametersCard from '../components/SettingsBlock/NewParametersCard.vue'
    import NewDocumentsCard from '../components/SettingsBlock/NewDocumentsCard.vue'
    import BotAlerts from '../components/SettingsBlock/BotAlerts.vue'
    import Header from '../components/Header.vue'

    import OtherSettingsBlock from '../components/SettingsBlock/OtherSettingsBlock.vue'

    export default {
        name: 'SettingsPage',

        setup() {
            const store = useMainStore()
            const botAlertsView = ref(false)
            const updateFlag = ref(false)
            const updateText = ref('Збереження...')
            
            const screenWidth = ref(window.innerWidth)

            const updateStudent = async () => {
                updateFlag.value = true
                const res = await updateStudentReq(
                    store.student, store.student.photo, store.student.id_code_files, store.student.passport_files, store.student.attorney_child_files, store.student.photo_for_passport
                )
                updateText.value = 'Збережено'
                setTimeout(() => {
                    updateFlag.value = false
                }, 1500)
                console.log(res)
            }

            const loading = ref(false)

            onBeforeMount(async () => {
                console.log('before mount')
                const studentRes = await store.getStudentData()
                console.log(studentRes)
                if (!studentRes) {
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    router.go()
                    return
                }
                await store.getGroupData()
                await store.getTrainerData()
                await store.getStudentIncomes()
                // await store.getBalanceState()
                await store.getTelegramBotSubscribed()
                console.log('store.student.group_id ----- ', store.student.group_id.length > 0)
                if (store.student.group_id.length > 0) {
                    await store.getPlanedTrainings()
                    await store.getAllGroupStudents()
                }
                await store.getStudentMessages()
                await store.getStudentBodyParameters()
                await store.getStudentCompletedTrainings()
                loading.value = true

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                    console.log(screenWidth.value)
                });
            })

            onMounted(async () => {
                await store.getTelegramBotSubscribed()
            })

            return {
                updateStudent,
                store,
                botAlertsView,
                updateFlag,
                updateText,
                loading,
                screenWidth
            }
        },

        components: {
            NewInfoCard,
            NewParametersCard,
            NewDocumentsCard,
            BotAlerts,
            OtherSettingsBlock,
            Header
        }
    }
</script>
