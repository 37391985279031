<template>
    <SquircleComponent 
        :smooth="50" 
        :radius="60" 
        :width="350" 
        :height="144" 
        :outlineWidth="1" 
        :fillColor="'#FF0000'" 
        :style="['border-color:' + tariff.tariff_color, 'background-color:' + tariff.tariff_color]" 
        class="z-[1000] overflow-hidden squircle-smooth relative bg-[#FBFFF1] border-[2px]" >
    <div>
        <!-- rounded-[26px] -->
        <div class="flex justify-between w-full relative top-[10px]">
            <div class="w-[30%]">
                <div class="rounded-full bg-black-emb w-[50px] h-[50px] text-[32px] text-center font-semibold mt-[7px] ml-[17px]" :style="['color:' + tariff.tariff_color]" >{{ tariff.tariff_training_count }}</div>
            </div>
            <div class="flex flex-col justify-center items-center text-center ml-auto mr-auto pb-[2px]">
                <div class="text-center text-black-emb mt-[9px] text-[20px] font-[800]">{{ tariff.tarrif_group_name }}</div>
                <div class="text-center text-black-emb text-[11px] font-[800] mt-[-2px]">{{ tariff.tariff_name }}</div>
            </div>
            <div class="text-black-emb z-[1000] relative w-[30%] top-[3px] leading-[15px] text-[14px] text-end pr-[19px]">
                <span class="text-[11px]">активний до</span><br>
                <b class="text-[14px]">{{ tariff.date_expired.split('T')[0].split('-').reverse().join('.') }}</b>
            </div>
        </div>
        <div class="">
            <div class="text-black-emb z-[1000] relative top-[25px] flex justify-between pl-[19px] pr-[19px]">
                <div class="text-[11px] w-[145px] h-[40px] overflow-hidden text-start leading-[14px]">{{ tariff.tariff_description }}</div>
                <div class="relative bottom-[4px] font-semibold text-end text-[32px]"><span class="font-bold">{{ tariff.tariff_price }}</span> грн</div>
            </div>
        </div>
    </div>
    </SquircleComponent>
</template>

<script>
    import SquircleComponent from './SquircleComponent.vue'

    export default {
        name: 'TariffCard',

        props: {
            tariff: {
                type: Object,
                default: () => {}
            }
        },

        components: {
            SquircleComponent
        }
    }
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Avenir Cyrillic Regular';
        src: url('@/mobileApp/assets/Avenir-regular.ttf');
    }
    
    .avenir-bold {
        font-family: 'Avenir Cyrillic Regular';
        font-weight: 900;
    }

    .squircle-smooth {
        --squircle-smooth: 0.8;
	    --squircle-radius: 20px;
    }
</style>