<template>
  <div class="contaier-fluid bg-[#FFF] pb-6 h-full">
    <router-view />
  </div>
</template>

<style lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    background-color: #FFF;
  }

  /* мобільні */
  @media (max-width: 767px) {
    .contaier-fluid {
      width: 100%;
      position: relative;
    }
  }

  /* таблети */
  @media (min-width: 768px) {
    .contaier-fluid {
      width: 400px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  /* ноути */
  @media (min-width: 992px) {
    .contaier-fluid {
      width: 400px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  /* пекарні */
  @media (min-width: 1200px) {
    .contaier-fluid {
      width: 400px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>