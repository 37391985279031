<template>
    <div class="mt-[30px]">
        <div class="text-gray-emb flex justify-between mb-[8px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[13px] font-bold">ОСОБИСТА ІНФОРМАЦІЯ</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>
        <div>
            <div class="student-data-table  mx-4 border-[#A5A3A3] border-opacity-25 rounded-2xl px-[8px] py-1">
                <div class="flex justify-between border-[#A5A3A3] border-opacity-25 items-center py-[10px]">
                    <div class="font-bold text-lg text-gray-emb">{{ store.student.name }}</div>
                    <div class="font-light text-sm text-gray-emb">ім'я</div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-[10px]">
                    <div class="font-bold text-lg text-gray-emb">{{ store.student.surname }}</div>
                    <div class="font-light text-sm text-gray-emb">прізвище</div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-[10px]">
                    <div v-if="store.student.birthday?.length > 0" class="font-bold text-lg text-gray-emb">{{ store.student.birthday.split('T')[0].split('-').reverse().join('.') }}</div>
                    <div v-else class="font-bold text-lg opacity-70 text-gray-emb">12.12.2030</div>
                    <div class="font-light text-sm text-gray-emb">день народження</div>
                </div>
                <div class="flex justify-between border-t-[0.2px] border-[#A5A3A3] border-opacity-25 items-center py-[10px]">
                    <div class="font-bold text-lg text-gray-emb">+{{store.student.phone}}</div>
                    <div class="font-light text-sm text-gray-emb">номер</div>
                </div>
            </div>
            <!-- <div class="border-[0.2px] border-[#A5A3A3] border-opacity-25 rounded-2xl px-3   py-2 mx-4 mt-2">
                <div>
                    <div class="border-b-[0.2px] border-[#A5A3A3] border-opacity-25 grid grid-cols-12 w-full">
                        <div class="border-r-[0.2px] border-[#A5A3A3] border-opacity-25 pr-3 py-2 text-center col-span-4">
                            <span v-if="store.student.height > 0" class="font-bold text-lg text-gray-emb">{{ store.student.height }}</span>
                            <span v-else class="font-bold text-lg opacity-60 text-gray-emb">0.00</span>
                            <br>
                            <span class="text-xs font-light text-gray-emb">зріст, см</span>
                        </div>
                        <div class="border-r-[0.2px] border-[#A5A3A3] border-opacity-25 px-5 py-2 text-center col-span-4">
                            <span v-if="store.student.weight > 0" class="font-bold text-lg text-gray-emb">{{ store.student.weight }}</span>
                            <span v-else class="font-bold text-lg opacity-60 text-gray-emb">0.00</span>
                            <br>
                            <span class="text-xs font-light text-gray-emb">вага, кг</span>
                        </div>
                        <div class=" pl-3 py-2 text-center col-span-4">
                            <span v-if="store.student.foot_size > 0" class="font-bold text-lg text-gray-emb">{{ store.student.foot_size }}</span>
                            <span v-else class="font-bold text-lg opacity-60 text-gray-emb">0.00</span>
                            <br>
                            <span class="text-xs font-light text-gray-emb">розмір ноги</span>
                        </div>
                    </div>
                    <div class="grid grid-cols-12">
                        <div class="border-r-[0.2px] border-[#A5A3A3] border-opacity-25 pr-3 py-2 text-center col-span-4">
                            <span v-if="store.student.leading_foot?.length > 0" class="font-bold text-lg text-gray-emb">{{ store.student.leading_foot }}</span>
                            <span v-else class="font-bold text-lg opacity-60 text-gray-emb">Ліва</span>
                            <br>
                            <span class="text-xs font-light text-gray-emb">нога</span>
                        </div>
                        <div class="border-r-[0.2px] border-[#A5A3A3] border-opacity-25 px-5 py-2 text-center col-span-4">
                            <span v-if="store.student.breast_volume > 0" class="font-bold text-lg text-gray-emb">{{ store.student.breast_volume }}</span>
                            <span v-else class="font-bold text-lg opacity-60 text-gray-emb">0.00</span>
                            <br>
                            <span class="text-xs font-light text-gray-emb">об'єм гр.</span>
                        </div>
                        <div class=" pl-3 py-2 text-center col-span-4"><span class="font-bold text-lg text-gray-emb">{{ store.startTrainingDate }}</span><br><span class="text-xs font-light text-gray-emb">старт в школі</span></div>
                    </div>
                </div>
                <div>
                    <div @click="$router.push('/stats')" class="text-gray-emb flex items-center justify-end ">
                        <div class="text-[14px]">ДИНАМІКА</div>
                        <div class="ml-[5px] mt-[1px] ">
                            <svg class="h-[14px]" width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 2L8.43043 8.17424C9.18986 8.90341 9.18986 10.0966 8.43043 10.8258L2 17" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div> -->  
        </div>
    </div>
</template>

<script>
    import { onMounted } from 'vue'
    import { useMainStore } from '@/mobileApp/store/mainStore'

    export default {
        name: 'student-data-block',

        setup() {
            const store = useMainStore()

            return {
                store
            }
        }
    }
</script>
