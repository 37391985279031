<template>
    <div class="w-full h-full fixed pt-[10px] bottom-0 z-[999] bg-[#FFF]">
        <div @click="$emit('close')" class="flex text-[#4A4E69] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#525A60" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-[#4A4E69]">
                Назад
            </div>
        </div>

        <div class="border-b-[1px] border-[#4A4E69] pb-2 text-gray-emb text-[20px] font-bold text-center w-[90%] mx-auto">
            {{ trainings[0].date.split('T')[0].split('-').reverse().join('.') }}
        </div>

        <div class="w-full flex justify-center mt-[44px]">
            <div class="">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" stroke="#6BA292" stroke-width="1.5"/>
                    <path d="M8 12.75C8 12.75 9.6 13.6625 10.4 15C10.4 15 12.8 9.75 16 8" stroke="#6BA292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
        <div class="w-full flex justify-center mt-[44px]">
            <div class="text-[#6BA292]" style="font-size: 20px; font-weight: 800; line-height: 20px; letter-spacing: 0em; text-align: center;">
                ви відвідали тренування
            </div>
        </div>

        <SquircleComponent
            v-for="training in mappedTrainings" 
            class="mt-[34px] relative bg-[#DBEBEA] mx-auto"
            :smooth="70" 
            :radius="44" 
            :width="354" 
            :height="78" 
            :outlineWidth="0"
        >
                <div class="flex text-[#4A4E69] relative top-[15px] items-center ml-[10px] mr-[16px] ">
                    <div class="w-full pt-[3px] flex items-center">
                        <div class="w-[52.1px] h-[44px] mr-[10px] relative bottom-[5px] rounded-full overflow-hidden border-[2px] border-[#525A60] ">
                            <img v-if="training?.trainer_photo" class="w-full h-full bg-white " :src="apiURL + 'static/' + training?.trainer_photo">
                            <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ training.trainer_label.split(' ')[0][0].toUpperCase() + training.trainer_label.split(' ')[1][0].toUpperCase() }}</div>
                        </div>
                        <div class="w-full mt-[px]">
                            <div class="w-full border-opacity-20 pb-[10px]">
                                <div class="flex w-full">
                                    <div class="font-bold relative text-[16px] text-gray-emb bottom-[5px] w-[37%]">{{ training.trainer_label.split(' ')[0] + ' ' + training.trainer_label.split(' ')[1][0] + '.'  }}</div>
                                    <div class="font-bold text-[13px] relative text-gray-emb bottom-[1px]">{{ training.tariff_group_name }}</div>
                                </div>
                                <div class="flex justify-start w-full">
                                    <div class="flex w-[37%] relative top-[6px]">
                                        <div class="text-[11px] text-gray-emb font-bold avenir-book">{{ training.start_time }}</div>
                                        <div class="mx-[3px] text-[11px] text-gray-emb">
                                            <svg class="scale-50 origin-center relative top-[2px]" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 11L5.29289 6.70711C5.62623 6.37377 5.79289 6.20711 5.79289 6C5.79289 5.79289 5.62623 5.62623 5.29289 5.29289L1 1" stroke="#525A60" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="text-[11px] text-gray-emb font-bold avenir-book">{{ training.end_time }}</div>
                                    </div>
                                    <div class="text-start text-[11px] text-gray-emb relative top-[6px] font-bold">{{ parseType(training.type) }}</div>
                                    <!-- <div class="truncate text-[#6BA292] font-semibold w-[120px]">{{ 0 }}/{{ training.max_clients }}</div> -->
                                    <div class="flex ml-auto mt-[-13px]">
                                        <div class="text-[11px] w-[55px] mt-[4px] text-gray-emb">
                                            {{training.location_label}}
                                        </div>
                                        <div 
                                            class="truncate text-[#6BA292] font-bold w-[30px] text-start"
                                            :class="{
                                                'text-[#6BA292]' : training.clientsCount < training.max_clients,
                                                'text-[#BF637D]' : training.clientsCount >= training.max_clients
                                            }"    
                                        >{{ training.clientsCount }}/{{ training.max_clients }}</div>
                                        <div 
                                            class="h-[22px] w-[22px] border-[2px] ml-[5px] rounded-full"
                                            :class="{
                                                'bg-[#6BA292] border-[#6BA292]' : training.clientsCount < training.max_clients,
                                                'bg-[#BF637D] border-[#BF637D]' : training.clientsCount >= training.max_clients
                                            }"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


        </SquircleComponent>


        <div class="w-full flex flex-col items-center justify-center mt-[20px]">
            <div class="text-[#6BA292] mt-[144px]" style="font-size: 20px; font-weight: 800; line-height: 20px; letter-spacing: 0em; text-align: center;">
                Ви молодець, не зупиняйтеся!
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getTrainingById, countClientsTrainingById } from '@/mobileApp/services/apiRequests'
    import SquircleComponent from '@/mobileApp/components/SquircleComponent.vue'

    export default {
        name: "CompletedTrainingView",

        props: {
            trainings: {
                type: Array,
                required: true
            }
        },

        setup(props, { emit }) {
            const apiURL = ref(process.env.VUE_APP_API_URL)
            const mappedTrainings = ref([])

            onMounted(async () => {
                props.trainings.forEach(async (training) => {
                    const response = await getTrainingById(training.training_id)
                    const currentTraining = response.data
                    const clientsCount = await countClientsTrainingById(training.training_id)
                    currentTraining.clientsCount = clientsCount.data
                    mappedTrainings.value.push(response.data)
                })
            })

            const parseType = (type) => {
                switch (type) {
                    case 'yoga':
                        return 'йога'
                    case 'barre':
                        return 'барре'
                    case 'pilates_ref':
                        return 'пілатес(реф)'
                    case 'pilates_mat':
                        return 'пілатес(мат)'
                    case 'straching':
                        return 'стретчінг'
                    case 'pilates_preg':
                        return 'пілатес(вагітні)'
                }
            }

            return {
                apiURL,
                mappedTrainings,
                parseType
            }
        },

        components: {
            SquircleComponent
        }
    }
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Avenir Book';
        src: url('@/mobileApp/assets/Avenir-bold.ttf') format('truetype');
        font-weight: 900;
    }

    .avenir-book {
        font-family: 'Avenir Book';
    }
</style>