<template>
    <div class="pt-[20px]">
        <div class=" w-full h-[178px] relative">
            <div class="flex items-end absolute justify-start w-[100%] h-[178px] z-[100]">
                <div :key="wallpaperKey" class="w-[100%] absolute h-[200px] flex items-end justify-start overflow-hidden">
                    <img v-if="store.student.wallpaper_photo?.length > 0" :src="apiURL + 'static/' + store.student.wallpaper_photo" class="object-contain h-[100%]">
                    <img v-else src="../../assets/student_photo_placeholder.svg" class="object-contain h-[90%] absolute bottom-0">
                </div>
                <!-- <img 
                    @click="uploadPhoto"
                    src="../../assets/upload_icon.svg" 
                    class="absolute left-[10px] top-[200px] cursor-pointer z-40"
                > -->
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    :value="inputValue"
                    @change="loadImage($event)"
                />
            </div>

            <AdaptiveTextBlockNew
                class="absolute top-[-25px] w-full"
                :name="store.student.name_eng" 
                :surname="store.student.surname_eng" 
                :number="store.student.player_number"
            />
        </div>
    </div>
</template>

<script>
    import { ref, watch, onMounted } from 'vue'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    import { useRouter } from 'vue-router'
    import { uuid } from 'vue3-uuid'
    import CircleProgress from "vue3-circle-progress"
    import AdaptiveTextBlockNew from './AdaptiveTextBlockNew.vue'
    import TrainingAttending from './TrainingAttending.vue'

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'student-preview',

        setup() {
            const store = useMainStore()
            const router = useRouter()

            const apiURL = process.env.VUE_APP_API_URL

            const fileInput = ref(null)
            const inputValue = ref(null)
            const wallpaperKey = ref(uuid.v4())

            const showTextRoll = ref(false)

            const activeToDate = ref(new Date().toLocaleDateString().split('T')[0].split('-').reverse().join('.'))

            const imageToCrop = ref({
                src: '',
                type: ''
            })

            const uploadPhoto = () => {
                fileInput.value.click()
            }

            const currentDate = new Date()
            const dateActiveTo = new Date(store.student.date_active_to)

            const formatDate = (date) => {
                const currentDate = new Date(date)
                const day = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`
                const month = currentDate.getMonth() + 1 > 9 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`
                return `${day}.${month}.${currentDate.getFullYear()}`
            }

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        console.log('imageToCrop.value.src', blob)
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        store.imageToCrop = imageToCrop.value
                        router.push('/wallpaper-loader')
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            onMounted(() => {
                wallpaperKey.value = uuid.v4()
                const currentDate = new Date()

                const mountValue = currentDate.getMonth() > 9 ? currentDate.getMonth() : `0${currentDate.getMonth()}`
                const mountValue2 = currentDate.getMonth() + 2 > 9 ? currentDate.getMonth() + 2 : `0${currentDate.getMonth() + 2}`

                if (store.studentBalance >= 0) {
                    const currentDate = new Date()
                    activeToDate.value = `01.${mountValue2}.${currentDate.getFullYear()}`
                } else {
                    activeToDate.value = 'борг'
                }
            })

            watch(() => store.student.name_eng, (newValue) => {
                showTextRoll.value = true
            })

            return {
                store,
                fileInput,
                uploadPhoto,
                inputValue,
                loadImage,
                wallpaperKey,
                activeToDate,
                showTextRoll,
                formatDate,
                currentDate,
                dateActiveTo,
                apiURL
            }
        },     

        components: {
            CircleProgress,
            AdaptiveTextBlockNew,
            TrainingAttending
        }
    }
</script>

<style lang="scss" scoped>
    .logo {
        filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.9));
    }

    .training-attending-styles {
        @apply w-[95%] mx-auto;
    }
</style>