<template>
    <div class="w-[400px] h-[191px] border-[1px] border-[#252526] bg-[#F2E9E4] rounded-[10px] pt-[10px]">
        <div class="bg-[#4A4E69] text-[13px] font-bold text-center rounded-[4px] mt-[5px] mx-[14px] py-[2px]">
            ПАРАМЕТРИ
        </div>
        <div class="flex justify-between mx-[14px] mt-[10px]">
            <div class="flex justify-between items-center align-middle w-[40%]">
                <div>
                    <input v-model="store.student.login" class="text-[#22223BFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="логін" type="text">
                </div>
                <div class="font-normal text-[#22223BFFF80] opacity-90 text-[11px] relative top-[3px]">
                    логін
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[40%]">
                <div>
                    <input v-model="store.student.password" class="text-[#22223BFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="пароль" type="text">
                </div>
                <div class="font-normal text-[#22223BFFF80] opacity-90 text-[11px]">
                    пароль
                </div>
            </div>
        </div>
        <hr class="mx-[14px] mt-[15px] bg-[#22223BFFF] fill-[#22223BFFF] stroke-[#22223BFFF] text-[#22223BFFF] opacity-30">
        <div class="flex justify-between mx-[14px] mt-[14px]">
            <div class="flex justify-between items-center align-middle w-[40%]">
                <div class="w-[30%]">
                    <input v-model="store.student.player_number" class="text-[#22223BFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[80%] truncate placeholder:opacity-60" placeholder="11" type="text">
                </div>
                <div class="font-normal text-[#22223BFFF80] w-[70%] opacity-90 text-start text-[11px]">
                    номер гравця
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[50%]">
                <div class="w-[55%]">
                    <input v-model="dateStartTraining" class="text-[#22223BFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="12.12.2012" type="date">
                </div>
                <div class="font-normal text-[#22223BFFF80] opacity-90 text-[11px]">
                    старт в клубі
                </div>
            </div>
        </div>

        <div class="flex justify-between mx-[14px] mt-[6px]">
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input v-model="store.student.height" class="text-[#22223BFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="160" type="text">
                </div>
                <div class="font-normal text-[#22223BFFF80] opacity-90 text-[11px] w-[60%] text-start">
                    зріст (см)
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input v-model="store.student.weight" class="text-[#22223BFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="100" type="text">
                </div>
                <div class="font-normal text-[#22223BFFF80] opacity-90 w-[60%] text-[11px] text-start">
                    вага (кг)
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input v-model="store.student.breast_volume" class="text-[#22223BFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="65" type="text">
                </div>
                <div class="font-normal text-[#22223BFFF80] opacity-90 text-[11px] w-[70%] text-end">
                    об'єм гр. (см)
                </div>
            </div>
        </div>

        <div class="flex justify-between mx-[14px] mt-[3px]">
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input v-model="store.student.leading_foot" class="text-[#22223BFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Ліва" type="text">
                </div>
                <div class="font-normal text-[#22223BFFF80] opacity-90 text-[11px] w-[60%] text-start">
                    нога
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input v-model="store.student.foot_size" class="text-[#22223BFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="41" type="text">
                </div>
                <div class="font-normal text-[#22223BFFF80] opacity-90 w-[60%] text-[11px] text-start">
                    р. ноги
                </div>
            </div>
            <div class="flex justify-between items-center align-middle w-[30%]">
                <div class="w-[30%]">
                    <input v-model="store.student.amplua" class="text-[#22223BFFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="НП" type="text">
                </div>
                <div class="font-normal text-[#22223BFFF80] opacity-90 text-[11px] w-[70%] text-end">
                    амплуа
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { ref, computed } from 'vue'
    import { useMainStore } from '../../store/mainStore'

    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    export default {
        name: 'parameters-card',

        setup(props, { emit }) {
            const store = useMainStore()

            const dateStartTraining = computed({
                get() {
                    if (!store.student.start_training.includes('T'))
                        return store.student.start_training
                    return formateDate(store.student.start_training)
                },
                set(value) {
                    store.student.start_training = new Date(value).toISOString().split('.')[0]
                }
            })

            return {
                dateStartTraining,
                store
            }
        },
    }
</script>



<style scoped>

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23FFFFFF" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

</style>