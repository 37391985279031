<template>
    <div>
        <div @click="$router.push('/')" class="flex text-[#A1D9F7] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-gray-emb">
                Назад
            </div>
        </div>
        <div class="w-[100%] mt-[20px] px-[10px] overflow-y-auto">
            <div class="text-gray-emb text-center font-medium mb-[10px] text-[16px]">ОПЛАТИ ПО ТАРИФАМ</div>
            <div class="h-px w-full bg-gray-emb rounded-full"></div>
            <div class="max-h-500px overflow-y-auto message-box mt-[20px]">
                <div class="w-full">
                    <div class="h-[80vh] rounded-2xl overflow-y-auto message-box px-[10px]">
                        <div v-if="loading" class="bg-[#FFF] fixed w-full h-full z-[120] left-[50%] translate-x-[-50%] mt-7">
                            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#990D35] mx-auto border-t-transparent"></div>
                        </div>
                        <div v-if="incomes.length > 0 && !loading" v-for="(income, id) in incomes" class="border-b-[0.2px] flex justify-between items-center border-[#A5A3A3] border-opacity-25 h-[52px]">
                            <div class="text-gray-emb w-[20%] text-center text-[12px]">{{ getDate(income.date) }}</div>
                            <div class="text-gray-emb w-[15%] text-center text-[12px]">{{ getTime(income.date) }}</div>
                            <div class="text-gray-emb text-[13px] w-[26%] text-end font-bold">{{ (income.amount - income.prepaid_income).toFixed(2) }} грн</div>
                            <div 
                                class="text-start w-[36%] relative pl-[20px] text-[13px] text-gray-emb"
                            >{{ income.tariff_name }}</div>
                            <div 
                                class="text-start w-[18%] relative pl-[20px] text-[13px] text-gray-emb"
                            >{{ receiverType(income.label) }}</div>
                        </div>
                        <div v-else-if="incomes.length == 0 && !loading">
                            <div colspan="6" class="w-full">
                                <div class="text-gray-emb text-[15px] flex justify-center items-center border-gray-emb bg-gray-emb bg-opacity-20 h-[52px] rounded-[10px]">Нема транзакцій</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, onBeforeMount, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../store/mainStore'
    import { getIncomesByBalanceId, getAllRealIncomeTariffs } from '../services/apiRequests'

    export default {
        
        name: 'IncomesView',

        setup() {
            const incomes = ref([])
            const outlays = ref([])
            const store = useMainStore()
            const router = useRouter()
            const currentYear = ref(new Date().getFullYear())
            const loading = ref(true)
            const getDate = (date) => {
                const newDate = new Date(date)
                const day = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate()
                const month = newDate.getMonth() + 1 < 10 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1
                return `${day}.${month}.${newDate.getFullYear()}`
            }

            const getTime = (date) => {
                const newDate = new Date(date)
                const hours = newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours()
                const minutes = newDate.getMinutes() < 10 ? `0${newDate.getMinutes()}` : newDate.getMinutes()
                return `${hours}:${minutes}`
            }
            const screenWidth = ref(window.innerWidth)

            const getTxType = (label) => {
                if (label.includes('тренер') || label.includes('Cashbox')) {
                    return 'готівка'
                } else if (label.includes('Wayforpay') || label.includes('Bank') || label.includes('Liqpay') || label.includes('IPay')) {
                    return 'безготівка'
                }
            }

            const copyToClipboard = (value) => {
                navigator.clipboard.writeText(value);
            };

            onBeforeMount(async () => {
                await uploadOutlays()
            })

            const receiverType = (label) => {
                if (label.includes('тренер')) {
                    return 'тренер'
                } else if (label.includes('Wayforpay') || label.includes('Ipay')) {
                    return 'кабінет'
                } else if (label.includes('terminal')) {
                    return 'термінал'
                } else if (label.includes('cash')) {
                    return 'каса'
                } else if (label.includes('Liqpay')) {
                    return 'liqpay'
                }
            }

            watch(() => window.innerWidth, (newWidth) => {
                screenWidth.value = newWidth
            })

            const nextYear = async () => {
                currentYear.value += 1
                await uploadOutlays()
            }

            const previousYear = async () => {
                currentYear.value -= 1
                await uploadOutlays()
            }

            const uploadOutlays = async () => {
                outlays.value = []
                const shortMonths = ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру']
                const responseIncomes = await getAllRealIncomeTariffs(store.student.student_id)
                incomes.value = responseIncomes.data.transactions.sort((a, b) => new Date(b.date) - new Date(a.date))
            }

            onMounted(async () => {
                await store.getStudentIncomes()
                loading.value = false
            })

            return {
                incomes,
                outlays,
                getDate,
                getTxType,
                copyToClipboard,
                receiverType,
                nextYear,
                previousYear,
                currentYear,
                screenWidth,
                loading,
                getTime
            }
        }

    }
</script>

<style lang="scss" scoped>

</style>