<template>
    <div class="mask-div" :style="{ 'mask-image': `url(${svgURL})` }">
        <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        svgURL: ''
      };
    },
    mounted() {
      this.generateSVG();
    },
    watch: {
      radius() {
        this.generateSVG();
      },
      radiusTopRight() {
        this.generateSVG();
      },
      radiusBottomRight() {
        this.generateSVG();
      },
      radiusBottomLeft() {
        this.generateSVG();
      },
      smooth() {
        this.generateSVG();
      },
      outlineWidth() {
        this.generateSVG();
      },
      fillColor() {
        this.generateSVG();
      }
    },
    methods: {
      generateSVG() {
        const svg = `
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.width} ${this.height}">
            <path d="${this.getSquirclePath()}" fill="black"/>
          </svg>
        `;
        console.log('generatedSVG', svg)
        const svgBlob = new Blob([svg], { type: 'image/svg+xml' });
        this.svgURL = URL.createObjectURL(svgBlob);
      },
      getSquirclePath() {
        const radii = [
          parseFloat(this.radius),
          parseFloat(this.radius),
          parseFloat(this.radius),
          parseFloat(this.radius)
        ];
        const smooth = parseFloat(this.smooth);
        const lineWidth = parseFloat(this.outlineWidth);
        
        const lineWidthOffset = lineWidth / 2;
        const path = `
            M${radii[0]},${lineWidthOffset}
            L${this.width - radii[1]},${lineWidthOffset}
            C${this.width - radii[1] / smooth},${lineWidthOffset},${this.width - lineWidthOffset},${radii[1] / smooth},${this.width - lineWidthOffset},${radii[1]}
            L${this.width - lineWidthOffset},${this.height - radii[2]}
            C${this.width - lineWidthOffset},${this.height - radii[2] / smooth},${this.width - radii[2] / smooth},${this.height - lineWidthOffset},${this.width - radii[2]},${this.height - lineWidthOffset}
            L${radii[3]},${this.height - lineWidthOffset}
            C${radii[3] / smooth},${this.height - lineWidthOffset},${lineWidthOffset},${this.height - radii[3] / smooth},${lineWidthOffset},${this.height - radii[3]}
            L${lineWidthOffset},${radii[0]}
            C${lineWidthOffset},${radii[0] / smooth},${radii[0] / smooth},${lineWidthOffset},${radii[0]},${lineWidthOffset}
            Z
        `;
        return path;
      }
    },
    props: {
        radius: Number,
        smooth: Number,
        outlineWidth: Number,
        fillColor: String,
        width: Number,
        height: Number
    }
  };
  </script>
  
  <style scoped>
  .mask-div {
    width: v-bind(width + 'px');
    height: v-bind(height + 'px');
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    mask-position: center;

  }
  </style>
  