<template>
    <div class="flex">
        <div 
            class="flex relative"
            :class="{
                'mt-[27px] w-[170px]' : screenWidth < 1800,
                'w-[210px]' : screenWidth >= 1800
            }"
        >
            <div class="height-arrows-block h-[60%] flex text-gray-emb relative left-[52px] top-[20px] justify-center mt-[1.4vw] items-center flex-col">
                <img src="../../assets/up_arrow.svg" class="h-[50%]">
                <div class="my-2"><span class="font-bold">{{ store.student.height }}</span> <span class="text-[14px]">см</span></div>
                <img src="../../assets/down_arrow.svg" class="h-[50%]">
            </div>
            <div class="h-[227px] relative w-[200px]">
                <div class="text-gray-emb text-[14px] text-center">
                    <span class="font-bold">{{ getClothSize(store.student.breast_volume) }}</span> розмір
                </div>
                <div class="w-full flex justify-center h-full mb-[10px]">
                    <img src="../../assets/student_params.svg" class="h-[85%]">
                </div>
                <div class="text-gray-emb text-[14px] absolute bottom-[5px] w-[100px] left-[52%] translate-x-[-50%] flex justify-between">
                    <div class="font-bold">{{ store.student.leading_foot == 'Права' ? store.student.leading_foot.slice(0,2) + '.' : 'Лв.' }}</div>
                    <div><span class="font-bold">{{ store.student.foot_size }}</span> р.</div>
                </div>
                <div class="text-gray-emb text-[14px] breast-volume-block  absolute text-center">
                    <span class="font-bold">{{ store.student.breast_volume }}</span> см
                </div>
                <div class="text-gray-emb text-[14px]  absolute bottom-[-20px] w-full text-center">
                    <span class="font-bold">{{ store.student.weight }}</span> вага (кг)
                </div>
            </div>
        </div>
        <div class="charts-block relative flex items-center">
            <div class="w-full">
                <div class="font-semibold text-gray-emb relative left-[20px] bottom-[14px] text-[15px]">ПАРАМЕТРИ</div>
                <div class="flex flex-col justify-between items-center">
                    <div class="relative flex flex-col w-full mb-[10px]">
                        <div class="text-gray-emb text-center text-[14px]">зріст</div>
                        <line-chart class="mx-auto" :color="'#3B9BF4'" :data="store.studentsHeights" :width="335" :height="70"></line-chart>
                    </div>
                    <div class="relative flex flex-col w-full">
                        <div class="text-gray-emb text-center text-[14px]">вага</div>
                        <line-chart class="mx-auto" :color="'#DFF726'" :data="store.studentsWeights" :width="335" :height="70"></line-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch, onMounted } from 'vue'
    import { useMainStore } from '../../store/mainStore'
    import LineChart from '../StudentBasicInfo/LineChart.vue'
    
    export default {
        name: 'StudentParametersBlock',


        setup() {
            const store = useMainStore()
            const screenWidth = ref(window.innerWidth)

            watch(() => window.innerWidth, (width) => {
                screenWidth.value = width
            })

            onMounted(() => {
                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            const getWeekDay = (dateString) => {
                const daysOfWeek = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
                const date = new Date(dateString)
                const dayOfWeek = daysOfWeek[date.getDay()]
                return dayOfWeek
            }

            const getClothSize = (chestCircumference) => {
                let size = ''
                
                if (chestCircumference < 94) {
                    size = 'XS'
                } else if (chestCircumference < 98) {
                    size = 'S'
                } else if (chestCircumference < 102) {
                    size = 'M'
                } else if (chestCircumference < 106) {
                    size = 'L'
                } else if (chestCircumference < 110) {
                    size = 'XL'
                } else if (chestCircumference < 114) {
                    size = 'XXL'
                } else {
                    size = 'XXXL'
                }

                return size
            }

            return {
                store,
                getWeekDay,
                getClothSize,
                screenWidth
            }
        },

        components: {
            LineChart
        }
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 0px) and (max-width: 1800px) {
        .height-arrows-block {
            @apply ml-[-3em] w-[5vw];
        }

        .breast-volume-block {
            @apply top-[70px] right-[10px];
        }

        .charts-block {
            @apply right-[10px];
        }
    }

    @media (min-width: 1800px) and (max-width: 8000px) {
        .height-arrows-block {
            @apply ml-[-1em] w-[3vw];
        }

        .breast-volume-block {
            @apply top-[70px] right-[20px];
        }

        .charts-block {
            @apply left-[21px];
        }
    }
</style>