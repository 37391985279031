<template>
    <div class="text-gray-700 px-[10px] rounded-[10px] ">
        <div class="flex flex-grow w-full h-full overflow-auto "> 
            <div class="flex flex-col overflow-x-auto flex-grow ">
                <div class="grid grid-cols-7 mt-1">
                    <div class="text-[#4A4E69] border-[1px] border-[#2B2A29] w-[160px] rounded-[4px] mx-[2px] pl-1 text-sm text-center font-bold">Пн</div>
                    <div class="text-[#4A4E69] border-[1px] border-[#2B2A29] w-[160px] rounded-[4px] mx-[2px] pl-1 text-sm text-center font-bold">Вт</div>
                    <div class="text-[#4A4E69] border-[1px] border-[#2B2A29] w-[160px] rounded-[4px] mx-[2px] pl-1 text-sm text-center font-bold">Ср</div>
                    <div class="text-[#4A4E69] border-[1px] border-[#2B2A29] w-[160px] rounded-[4px] mx-[2px] pl-1 text-sm text-center font-bold">Чт</div>
                    <div class="text-[#4A4E69] border-[1px] border-[#2B2A29] w-[160px] rounded-[4px] mx-[2px] pl-1 text-sm text-center font-bold">Пт</div>
                    <div class="text-[#990D35] border-[1px] border-[#2B2A29] w-[160px] rounded-[4px] mx-[2px] pl-1 text-sm text-center font-bold">Сб</div>
                    <div class="text-[#990D35] border-[1px] border-[#2B2A29] w-[160px] rounded-[4px] mx-[2px] pl-1 text-sm text-center font-bold">Нд</div>
                </div>
                <div 
                    class="grid flex-grow w-full h-full grid-cols-7 grid-rows-5 gap-px pt-px mt-1"
                    :class="{
                        'grid-rows-6': ((days[5]?.weekDayId == 6 && days[5]?.dayNumber == 1) || days[6]?.weekDayId == 7 && (days[6]?.dayNumber == 1 || days[6]?.dayNumber == 2)) && unparsedDays.at(-1).dayNumber > 30, 
                        'grid-rows-5': (days[5]?.weekDayId == 6 && days[5]?.dayNumber != 1) || days[6]?.weekDayId == 7 && (days[6]?.dayNumber != 1 || days[6]?.dayNumber != 2)
                    }"    
                >
                    <new-day-card class="" v-for="day in days" @viewFull="$emit('viewFull', day)" @addTraining="$emit('addTraining', $event)" :day="day"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NewDayCard from './NewDayCard.vue'
    export default {
        name: 'calendar-component',

        props: {
            days: {
                type: Array,
                required: true
            },

            unparsedDays: {
                type: Array,
                required: true
            }
        },

        updated() {
        },

        components: {
            NewDayCard
        }
    }
</script>
