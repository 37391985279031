<template>
    <div class="mt-[-20px]">
        <div class="text-gray-emb flex justify-between mb-[0px] items-center">
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
            <div class="text-[13px] font-bold">ФІНАНСИ</div>
            <div class="text-[9px] tracking-[0.2em]">//////////////</div>
        </div>

        <!-- <div class="text-end mr-3 text-gray-emb">
            оплати <b>{{ currentYear }}</b>
        </div> -->

        <div class="h-[70px] mx-3 flex justify-between align-bottom items-end">
            <div v-for="month in incomesByMonth" class="flex flex-col justify-center items-center w-[20px]">
                <div class="text-gray-emb text-xs">
                    {{ month.incomes > 0 ? month.incomes : 0 }}
                </div>
                <div class="w-[20px]  bg-[#4A4E69]" :style="{ height : `${month.barHeight > 0 ? month.barHeight : 2 }px` }"></div>
                <div class="text-xs text-gray-emb">
                    {{ month.month }}
                </div>
            </div>
        </div>


        <SquircleComponent
            :smooth="60" 
            :radius="22" 
            :width="screenWidth * 0.92" 
            :height="44" 
            :outlineWidth="1"
            @click="$router.push('/income-history')"
            class="border-violet-emb bg-violet-emb rounded-[9px] text-gray-emb px-3 h-[44px] flex items-center w-[92%] text-base font-semibold align-middle justify-between mt-5 mx-auto"
        >
            <div class="w-full text-[13px] text-center">Мої оплати</div>
        </SquircleComponent>

        <SquircleComponent
            :smooth="60" 
            :radius="22" 
            :width="screenWidth * 0.92" 
            :height="44" 
            :outlineWidth="1"
            @click="$router.push('/payment-view')"
            class="border-green-emb bg-green-emb rounded-[9px] text-gray-emb px-3 h-[44px] flex items-center w-[92%] text-base font-semibold align-middle justify-between mt-5 mx-auto"
        >    
            <div class="w-full text-[13px] text-center">Обрати абонемент</div>
        </SquircleComponent>

    </div>
</template>

<script>
    import { onMounted, ref, watch } from 'vue'
    import SquircleComponent from '@/mobileApp/components/SquircleComponent.vue'

    export default {
        name: 'finance-block',

        props: {
            studentIncomes: {
                type: Array,
            }
        },

        setup(props) {
            const currentYear = ref(new Date().getFullYear())
            const incomesByMonth = ref([])

            const screenWidth = ref(window.innerWidth)

            const mapIncomesMounthPerAmount = (studentIncomes) => {
                let incomesWithMounth = []
                studentIncomes.forEach(income => {
                    const incomeDate = new Date(income.date)
                    const incomeMonth = incomeDate.toLocaleString('uk', { month: 'short' })
                    const incomeYear = incomeDate.getFullYear()

                    incomesWithMounth.push({
                        month: incomeMonth,
                        year: incomeYear,
                        amount: income.amount
                    })
                })
                return incomesWithMounth
            }

            const buildDiagram = () => {
                incomesByMonth.value = []
                const mappedIncomes = mapIncomesMounthPerAmount(props.studentIncomes)
                const incomesToMonth = []

                for (let i = 0; i < 12; i++) {
                    const currentMonth = new Date(currentYear.value, i, 1).toLocaleString('uk', { month: 'short' })
                    const currentMonthIncomes = mappedIncomes.filter(item => item.month === currentMonth && item.year === currentYear.value) 
                    
                    let currentIncome = 0

                    if (currentMonthIncomes) {
                        currentMonthIncomes?.forEach(income => {
                            currentIncome += income.amount
                        })
                    }
                    incomesToMonth.push(currentIncome)
                }

                const maxIncome = Math.max(...incomesToMonth)

                for (let i = 0; i < 12; i++) {
                    const currentMonth = new Date(currentYear.value, i, 1).toLocaleString('uk', { month: 'short' })
                    const currentMonthIncomes = mappedIncomes.filter(item => item.month === currentMonth && item.year === currentYear.value) 
                    
                    let currentIncome = 0

                    if (currentMonthIncomes) {
                        currentMonthIncomes?.forEach(income => {
                            currentIncome += income.amount
                        })
                    }
                    
                    const percentOfMaxIncome = (currentIncome * 100) / maxIncome
                    const heightOfBar = (percentOfMaxIncome * 30) / 100
                    incomesByMonth.value.push({
                        month: currentMonth,
                        incomes: currentIncome,
                        barHeight: heightOfBar
                    })
                }
            }

            const genInvoce = () => {
                window.open('https://secure.wayforpay.com/page?vkh=63d3cb16-7eb4-488b-834f-1df322d1dba8', '_blank', 'noreferrer')
            }

            watch(() => props.studentIncomes, () => {
                if (props.studentIncomes.length != 0) {
                    buildDiagram()
                }
            })

            onMounted(() => {
                buildDiagram()

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            return {
                genInvoce,
                currentYear,
                incomesByMonth,
                screenWidth
            }
        },

        components: {
            SquircleComponent
        }
    }
</script>
