<template>
    <div> 
        <component :is="currentComponent" ></component>
    </div>
    <!-- v-if="/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgentName)" -->
    <!-- <div v-else>
        Лише мобільні пристрої
    </div> -->
</template>

<script>
    import Login from '@/mobileApp/views/LoginView.vue'
    import Main from '@/mobileApp/views/MainView.vue'
    import EditData from '@/mobileApp/views/EditStudentDataView.vue'
    import AgreementPersonalData from '@/mobileApp/views/agreements/AgreementPersonalDataView.vue'
    import SchoolRules from '@/mobileApp/views/agreements/SchoolRulesView.vue'
    import Loading from '@/mobileApp/views/LoadingView.vue'
    import Payment from '@/mobileApp/views/PaymentView.vue'
    import PaymentRedirect from '@/mobileApp/views/PaymentRedirectView.vue'
    import WallpaperLoader from '@/mobileApp/components/StudentPreview/WallpaperLoader.vue'
    import IncomesView from '@/mobileApp/views/IncomesView.vue'
    import MessagesView from '@/mobileApp/views/MessagesView.vue'
    import CurrentMessageView from '@/mobileApp/views/CurrentMessageView.vue'
    import TeamView from '@/mobileApp/views/TeamView.vue'
    import AllStudentsInGroup from '@/mobileApp/views/AllStudentsInGroup.vue'
    import StatsPage from '@/mobileApp/views/StatsPage.vue'
    import PhotoLoader from '@/mobileApp/components/DocumentsBlock/PhotoLoader.vue'

    import { ref, watch } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { onMounted, onUpdated } from 'vue'


    export default {
        name: 'MainLayout',
        
        setup() {
            const currentComponent = ref('Loading')
            const router = useRouter()
            const route = useRoute()
            const screenWidth = ref(window.innerWidth)

            const isMobile = ref(true)

            const userAgentName = navigator.userAgent
            
            watch(screen.width, (newWidth) => {
                screenWidth.value = newWidth
            })

            onMounted(async () => {
                if (localStorage.access_token && localStorage.refresh_token) {                    
                    currentComponent.value = route.meta.component
                } else {
                    console.log('no tokens')
                    currentComponent.value = 'Login'
                }
            })

            onUpdated(() => {
                if (localStorage.access_token && localStorage.refresh_token) {
                    currentComponent.value = route.meta.component
                }
            })

            return {
                currentComponent,
                screenWidth,
                isMobile,
                userAgentName
            }
        },

        components: {
            Login,
            Main,
            EditData,
            AgreementPersonalData,
            SchoolRules,
            Loading,
            Payment,
            PaymentRedirect,
            WallpaperLoader,
            IncomesView,
            MessagesView,
            CurrentMessageView,
            TeamView,
            AllStudentsInGroup,
            StatsPage,
            PhotoLoader
        }
    }
</script>