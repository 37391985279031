<template>
    <div class="w-max">
        <div class="photo-container relative bottom-[10px]">
            <ImageCropper
                v-if="showImageCropper"
                :image="imageToCrop"
                :height="'450px'"
                :width="'430px'"
                class="relative top-[2px]" 
                @processedImage="processImage"
            />
            <div v-else>
                <!-- <img v-if="studentPhotoURL" :src="studentPhotoURL" class="object-contain h-full">
                <img v-else-if="store.student.wallpaper_photo" :src="apiURL + 'static/' + store.student.wallpaper_photo" class="object-contain h-full">
                <img v-else src="../../assets/student_photo_placeholder.svg" class="object-contain">
             -->
                <div class="relative">
                    <img v-if="studentPhotoURL" :src="studentPhotoURL" class="object-contain h-full">
                    <img v-else-if="store.student.wallpaper_photo" :src="apiURL + 'static/' + store.student.wallpaper_photo" class="object-contain h-full">
                    <img v-else src="../../assets/student_photo_placeholder.svg" class="object-contain">
                    <div class="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-[#F2E9E4] to-transparent"></div>
                </div>
            
            </div>
            <div class="absolute ml-[320px]">
                <img 
                    @click="uploadPhoto"
                    src="../../assets/upload_icon_light.svg" 
                    class="cursor-pointer "
                >
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    :value="inputValue"
                    @change="loadImage"
                />
            </div>
        </div>
        <TrainingAttending class="training-attending-styles relative"/>
    </div>
</template>

<script>
    import { ref, onBeforeMount, watch } from 'vue'
    import { updateStudentPhoto, updateStudentWallpaper } from '../../services/apiRequests'
    import { useMainStore } from '../../store/mainStore'
    import TrainingAttending from './TrainingAttending.vue'
    import ImageCropper from './ImageCropper.vue'

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'StudentWithTrainingsBar',

        props: {
            photoHeight: {
                type: String,
                default: '257px'
            },

            photoWidth: {
                type: String,
                default: '461px'
            }
        },

        setup() {
            const store = useMainStore()
            const apiURL = process.env.VUE_APP_API_URL
            const inputValue = ref(null)
            const imageToCrop = ref({
                src: '',
                type: ''
            }) 
            const studentPhotoURL = ref(null)
            const showImageCropper = ref(false)
            const fileInput = ref(null)

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        showImageCropper.value = true
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            const uploadPhoto = () => {
                fileInput.value.click()
            }

            
            const processImage = async (image) => {
                console.log('store.image -------- ', image)
                await updateStudentWallpaper(store.student.student_id, image)
                // store.student.photo = image
                studentPhotoURL.value = image
                showImageCropper.value = false
            }

            return {
                store,
                apiURL,
                inputValue,
                imageToCrop,
                loadImage,
                uploadPhoto,
                showImageCropper,
                fileInput,
                studentPhotoURL,
                processImage
            }
        },

        components: {
            TrainingAttending,
            ImageCropper
        }
    }
</script>

<style lang="scss" scoped>
    .photo-container {
        @apply select-none flex items-end justify-center mb-[2px];
    }
    .photo-container {
        width: v-bind(photoWidth);
        height: v-bind(photoHeight);
    }

    .training-attending-styles {
        @apply w-[80%] mx-auto;
    }
</style>