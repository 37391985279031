<template>
    <div class="absolute bg-white w-full pb-4">
        <div @click="$router.push('/')" class="flex text-gray-emb items-center ml-3 mt-4 mb-5">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#22223B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2 text-gray-emb">
                Назад
            </div>
        </div>
        <button @click="updatePersonalDataState" class="bg-green-emb w-[90%] rounded-[9px] text-gray-emb px-3 py-2 mx-auto mt-4 text-base font-semibold flex items-center align-middle justify-between">
            <div class="w-full">Приймаю</div>

            <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="black"/>
            </svg>

        </button>
        <div class="text-xs text-[#F8FF82] w-[90%] mx-auto mt-7">
            <div class="text-gray-emb text-[12px] font-semibold">Договір на обробку персональних даних</div>
            <br>
            <div style="width: 100%; height: 2465px"><span style="color: black; font-size: 10px; font-weight: 300; word-wrap: break-word">
                ДОГОВІР (ОФЕРТИ)

надання спортивних послуг

м.Ужгород                                                                                        «05» квітня 2024року

Цей договір, в якому одна сторона Фізична особа-підприємець Мойсюк Юліанна Вячеславівна ідентифікаційний номер платника податку 3709704449 (надалі – Виконавець), яка є платником єдиного податку 3-ї групи, з одного боку, і будь-яка особа, яка прийняла (акцептувала) дану пропозицію (надалі – Замовник), з іншого боку, далі разом іменуються як Сторони, уклали даний договір (далі — Договір) адресований необмеженому колу осіб, який являється офіційною публічною пропозицією Виконавця, укласти з Замовниками договір надання спортивних послуг, опис яких розміщений у відповідному розділі сайту https:\\client.embody.sportkit.app, приймають умови цього Договору про нижченаведене:

1.ЗАГАЛЬНІ ПОЛОЖЕННЯ

1.1. Договірні відносини між Виконавцем і Замовником оформляються у вигляді Договору публічної оферти. Натискання в інтернет-ресурсі на сторінці оформлення кнопки “ПЕРЕЙТИ ДО ОПЛАТИ”, означає, що Замовник, незалежно від статусу (фізична особа, юридична особа, фізична особа — підприємець), згідно чинного українського законодавства, взяв до виконання умови Договору публічної оферти, які вказані нижче.

1.2. Договір публічної оферти є публічним, тобто відповідно до статей 633, 641 Цивільного кодексу України його умови однакові для всіх Замовників незалежно від статусу (фізична особа, юридична особа, фізична особа — підприємець). При повній згоді з даним Договором, Замовник приймає умови, порядок оформлення Замовлення та оплати і надання послуг Виконавцем, відповідальності за недобросовісне Замовлення і за невиконання умов цього Договору.

2.ТЕРМІНИ І ВИЗНАЧЕННЯ

«Договір публічної оферти» - публічний договір, зразок якого розміщений на Веб- сайту https:\\client.embody.sportkit.app і застосування якого є обов’язковим для всіх третіх осіб, що містить пропозицію Виконавця, щодо надання Послуг, опис яких знаходиться на Веб-сайті Виконавця, спрямованих невизначеному колу осіб, у тому числі Замовникам.

«Акцепт» - прийняття Замовником пропозиції Виконавця щодо придбання Послуг, опис яких знаходиться на Веб-сайті Виконавця https:\\client.embody.sportkit.app, шляхом купівлі послуги.

«Оферта» - цей документ «Договір публічної оферти на оплатне надання послуг навчання», розміщений у мережі інтернет за адресою https:\\client.embody.sportkit.app

«Послуга» - спортивні послуги, інформація про які розміщена на Веб-сайті https://methodwriting.com.ua/ . Послуги можуть надаватися за допомогою засобів телекомунікаційного, електронного зв’язку (в тому числі через мережу інтернет).

«Замовник» - будь-яка дієздатна фізична, юридична особа, фізична особа-підприємець, згідно чинного українського законодавства, що відвідали Веб-сайт https:\\client.embody.sportkit.app  та мають намір придбати ту чи іншу Послугу.

«Виконавець» - фізична особа-підприємець, яка є власником чи розповсюджувачем Послуг згідно чинного українського законодавства за допомогою Веб-сайту https:\\client.embody.sportkit.app та має намір їх надавати/продавати.

«Договір» - договір між Замовником і Виконавцем на надання Послуг, який укладається за допомогою Акцепту Оферти.

«Замовлення» - належно оформлена та розміщена на Веб-сайті Виконавця особиста сторінка Замовника на купівлю Послуг, що адресується Виконавцю та за допомогою якого платіжною системою генерується Рахунок-фактура до сплати.

«Законодавство» - чинне законодавство України, яке може бути застосоване до правовідносин, пов’язаних з виконанням та/або які виходять з виконання описаних в цьому Договорі зобов'язань.

3.ПРЕДМЕТ ДОГОВОРУ

3.1. Предметом Договору є платне надання Виконавцем Замовнику спортивних Послуг на умовах Оферти.

3.2. Виконавець та Замовник підтверджують, що чинний Договір не є фіктивною чи удаваною угодою або угодою, укладеною під впливом тиску чи обману.

3.3. Виконавець підтверджує, що діє у відповідності з вимогами діючого законодавства України.

4.ПРАВА ТА ОБОВ'ЯЗКИ ВИКОНАВЦЯ

4.1. Виконавець зобов’язаний:

a. при наданні Послуги Виконавець зобов’язаний дотримуватись умов Оферти;
b. виконувати замовлення Замовника в разі надходження оплати від Замовника;
c. надати Замовнику Послуги згідно з пропозиціями і описами на відповідній сторінці Веб-сайту https:\\client.embody.sportkit.app, оформленим Замовленням і умовами даного Договору;

4.2. Виконавець має право:

a. в односторонньому порядку призупинити надання послуг за цим Договором у випадку порушення Замовником умов цього Договору;

b. Виконавець має право залучати третіх осіб для виконання своїх зобов’язань по наданню Послуг за Договором;

c. Виконавець має право змінити Оферту, як це передбачено в п. 8.3 цієї Оферти;

d. Виконавець має право публікувати та поширювати інформацію про хід, спосіб та результати надання Послуг Замовнику.

5.ПРАВА ТА ОБОВ'ЯЗКИ ЗАМОВНИКА

5.1. Замовник зобов’язаний:

a. своєчасно оплатити і отримати замовлення на умовах цього Договору;

b. ознайомитись з інформацією про Послуги, яка розміщені на сайті Виконавця;

Протягом терміну дії цього Договору і протягом 20 (двадцяти) років з моменту його припинення за будь-якої причини, Виконавець тримає в конфіденційності всю інформацію, отриману в зв’язку з укладенням та виконанням цього Договору (далі — «Конфіденційна інформація») і утримується від розкриття такої інформації третім особам, за винятком, коли це вимагається згідно з українським законодавством або будь-яким відповідним національним або міжнародним регулюючим органом.

Замовник не має права продавати, ліцензувати, субліцензувати, передавати або іншим чином розголошувати будь-яку конфіденційну інформацію отриману від Виконавця третім особам, за винятком випадків, прямо передбачених цим Договором;

Замовник гарантує, що пов’язані з ним треті особи, його співробітники та професійні консультанти, які мають доступ до конфіденційної інформації, зобов’язані приймати зобов’язання конфіденційності, не менше суворі, ніж ті, що описані тут.

5.2. Замовник має право:

a. оформити Замовлення на відповідній сторінці Веб-сайту https:\\client.embody.sportkit.app;

b. ознайомитись з описом Послуг на відповідній сторінці Веб-сайту https:\\client.embody.sportkit.app.

6.ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ

6.1. Замовник самостійно оформлює Замовлення на відповідній сторінці Веб-сайту https:\\client.embody.sportkit.app шляхом вибору функції “Перейти до оплати”, отримання від платіжної системи можливості оплатити послугу на розрахунковий рахунок Виконавця.

7.ЦІНА ДОГОВОРУ І ПОРЯДОК ОПЛАТИ

7.1. Ціна кожної окремої Послуги визначається Виконавцем та зазначається на відповідній сторінці Веб-сайту https:\\client.embody.sportkit.app.

7.2. Вартість Замовлення може змінюватись в залежності від ціни, кількості чи номенклатури Послуг.

7.3. Замовник може оплатити Замовлення наступними способами:

a.за допомогою банківського переказу грошей на поточний рахунок Виконавця;

b.іншим способом за домовленістю з Виконавцем.

7.4. Послуги вважаються сплаченими Замовником з моменту отримання Виконавцем підтвердження з банку про надходження всієї суми оплати на поточний рахунок Виконавця.

В окремих випадках, на власний розсуд Виконавця, підтвердженням факту оплати може бути:

а. копія платіжного доручення у разі безготівкової форми оплати;

b. копія квитанції про оплату з печаткою банку, через який здійснено оплату;
c. копія квитанції терміналу, тощо.

7.5. Умови повернення коштів:

за 7 днів до першого відвідування  - повертаємо 100% оплати;

за 6 – 1 день до першого відвідування  - повертаємо 50% оплати;

після початку занять - оплата не повертається.

8. СТРОК ДІЇ ТА ЗМІНА УМОВ ОФЕРТИ

8.1.Виконавець починає надавати послуги за цим Договором в дату початку надання послуг.

8.2.Договір укладається на невизначений термін та набуває чинності з моменту натискання на кнопку “ПЕРЕЙТИ ДО ОПЛАТИ”, цим Замовник надає згоду здійснити покупку наявних у Виконавця Послуг та діє до моменту отримання Замовником Послуг від Виконавця і повного розрахунку за ними.

8.3.Виконавець залишає за собою право внести зміни в умови Оферти та/або відкликати Оферту в будь-який момент на власний розсуд. У разі внесення Виконавцем змін в Оферту такі зміни набувають чинності з моменту розміщення зміненого тексту Оферти в мережі інтернет за зазначеною в п. 2 Договору адресою, якщо інший строк набуття чинності змін не визначено додатково під час такого розміщення.

8.4.Якщо Рахунок-фактура на оплату, згенерований на основі Замовлення, не оплачений Замовником протягом 5 банківських днів в повному обсязі, Договір може бути розірваним Виконавцем в односторонньому порядку, без повідомлення про таке розірвання Замовника.

9.ВІДПОВІДАЛЬНІСТЬ СТОРІН ТА ВИРІШЕННЯ СПОРІВ

9.1. відповідають за невиконання або неналежне виконання умов цього Договору в порядку, передбаченому цим Договором і чинним законодавством України.

9.2. Виконавець не несе жодної відповідальності перед Замовником, якщо Замовник:

а) порушив строки та умови оплати за цим Договором та/або

b) не скористався послугами Виконавця у терміни, які повідомив Виконавець на відповідній сторінці Веб-сайту https:\\client.embody.sportkit.app та/або електронною поштою та/або іншими засобами телекомунікаційного зв’язку;

9.3. Виконавець не несе відповідальності перед Замовником за будь-які прямі або непрямі збитки, які сталися через: використання або неможливості використання Послуги, ресурсів сайту https:\\client.embody.sportkit.app та/або окремих сервісів Виконавця; заяви або поведінку будь-якої третьої особи під час одержання Послуги.

9.4. У разі виникнення спорів, пов’язаних з виконанням Сторонами цього Договору, за винятком спорів щодо стягнення заборгованості з Замовника, Сторони зобов’язуються вирішувати їх шляхом переговорів з дотриманням претензійного порядку. Термін розгляду претензії - 7 (сім) календарних днів від дати її отримання. По спорах у зв’язку із стягненням заборгованості з Замовника дотримання претензійного порядку не потрібно.

9.5. Усі спори, розбіжності або вимоги, що виникають з цього Договору або у зв’язку з ним, у тому числі що стосуються його виконання, порушення, припинення або недійсності, підлягають вирішенню у відповідному суді згідно матеріального і процесуального права України.

9.6. Сукупний розмір відповідальності Виконавця за Договором, включаючи розмір штрафних санкцій (пені, неустойок) та/або відшкодовуваних збитків за будь-яким позовом або претензією відносно Договору чи його виконання, обмежується 1% вартості Послуг за Договором.

10. ФОРС-МАЖОРНІ ОБСТАВИНИ ТА ОБСТАВИНИ НЕПЕРЕБОРНОЇ СИЛИ

10.1. Сторони не несуть відповідальності за невиконання будь-якого зі своїх зобов’язань, за винятком зобов’язань по оплаті, якщо доведуть, що таке невиконання було викликано форс-мажорними обставинами та/або обставинами непереборної сили, тобто, подіями або обставинами, які дійсно знаходяться поза контролем такої Сторони, що настали після укладення цього Договору та носять непередбачений і невідворотний характер.

10.2. До форс-мажорних обставин та/або обставинами непереборної сили належать, зокрема, природні катаклізми, страйки, пожежі, повені, вибухи, обмерзання, війни (як оголошені, так і неоголошені), заколоти, псування мережі інтернет та засобів телекомунікацій, викликані аваріями або несприятливими, погодними умовами, небезпеки і випадковості, ембарго, катастрофи, обмеження, що накладаються державними органами (включаючи розподілу, пріоритети, офіційні вимоги, квоти і ціновий контроль, тощо), якщо ці обставини безпосередньо вплинули на виконання цього Договору.

10.3.Сторона, для якої стало неможливим виконання зобов’язань за цим Договором через настання форс-мажорних обставин та/або обставин непереборної сили, повинна негайно інформувати іншу Сторону про виникнення вищевказаних обставин, а також протягом 30 (тридцяти) календарних днів надати іншій Стороні підтвердження таких обставин. Таким підтвердженням буде довідка, сертифікат або інший відповідний документ, виданий уповноваженим державним органом, розташованим за місцем виникнення форс-мажорних обставин та/або обставин непереборної сили.

10.4. Час, який потрібен Сторонам для виконання своїх зобов’язань за цим Договором, буде продовжено на будь-який термін, протягом якого було відкладено виконання зобов'язань через вищеперераховані обставини.

10.5. Якщо через форс-мажорних обставин та/або дію обставин непереборної сили невиконання зобов’язань за цим Договором триває більше трьох місяців, кожна із Сторін має право розірвати цей Договір в односторонньому порядку, письмово повідомивши про це іншу сторону.

Незважаючи на настання форс-мажорних обставин та/або дію обставин непереборної сили, перед припиненням цього Договору внаслідок таких обставин, Сторони здійснюють остаточні взаєморозрахунки.

11.ІНШІ УМОВИ ДОГОВОРУ

11.1. Інформація про Замовника використовується в цілях виконання його Замовлення, надсилання пропозицій та інформаційних матеріалів Виконавця та/або розміщення відгуків Замовника про надані послуги Виконавцем, у разі їх наявності.

11.2. Власним акцептування Договору або реєстрацією на Веб- сайту https:\\client.embody.sportkit.app Замовник, підтверджує, що він повідомлений про включення його персональних даних до бази персональних даних, володільцем якої є Виконавець, з метою обробки персональних даних, зазначеної у цьому пункті, а також про свої права, зазначені у статті 8 Закону України «Про захист персональних даних».

Замовник добровільно надає згоду на збір та обробку власних персональних даних з наступною метою: дані, що стають відомі використовуватимуться в комерційних цілях, в тому числі для обробки замовлень на придбання Послуг, отримання інформації про Замовлення, надсилання телекомунікаційними засобами зв’язку (електронною поштою, мобільним зв’язком) рекламних та спеціальних пропозицій, інформації про акції, розіграші або будь-якої іншої інформації про діяльність Виконавця.

Для цілей, передбачених цим пунктом, Замовнику мають право направлятися листи, повідомлення та матеріали на поштову адресу, e-mail Замовника, а також відправлятися sms-повідомлення, здійснюватися дзвінки на вказаний в Замовлені та/або анкеті телефонний номер.

11.3. Замовник дає право здійснювати обробку його персональних даних, у тому числі: поміщати персональні дані в бази даних (без додаткового повідомлення про це), здійснювати довічне зберігання даних, їх накопичення, оновлення, зміну (у міру необхідності). Виконавець бере на себе зобов’язання забезпечити захист даних від несанкціонованого доступу третіх осіб, не поширювати і не передавати дані будь-якій третій стороні (крім передачі даних пов’язаним особам, комерційним партнерам, особам, уповноваженим Виконавцем на здійснення безпосередньої обробки даних для зазначених цілей, а також на обов’язковий запит компетентного державного органу).

11.4. У випадку небажання отримувати розсилку, Замовник має право звернутися до Виконавця, написавши про відмову від отримання рекламних та/або інформаційних матеріалів, надіславши його на поштову або електронну адресу.

11.5. Виконавець не несе відповідальність за зміст і правдивість інформації, що надається Замовником при оформленні замовлення.

11.6. За винятком гарантій, прямо зазначених у тексті Оферти, Виконавець не надає жодних інших прямих або домислюваних гарантій за Договором і прямо відмовляється від будь-яких гарантій та/або умов відносно збереження прав, відповідності Послуг конкретним цілям Замовника.

11.7. Погоджуючись з умовами та приймаючи умови цієї Оферти шляхом Акцепту Оферти, Замовник (чи представник Замовника, в т. ч. фізична особа, належним чином уповноважена укласти Договір від імені Замовника) запевняє Виконавця і гарантує Виконавцю, що:

11.7.1. Замовник (представник Замовника) надав достовірні дані, у тому числі персональні дані Замовника (представника Замовника) для оформлення платіжних документів з оплати Послуг.

11.7.2. Замовник укладає Договір добровільно, при цьому Замовник (представник Замовника):

a. повністю ознайомився з умовами Оферти,

b. повністю розуміє предмет Оферти та Договору, c. повністю розуміє значення та наслідки своїх дій щодо укладання та виконання Договору.

11.7.3. Замовник (представник Замовника) має всі права та повноваження, необхідні для укладання та виконання Договору.


            </span></div>
        </div>
    </div>
</template>

<script>
    import { useRouter, useRoute } from 'vue-router'
    import { useMainStore } from '@/mobileApp/store/mainStore'
    export default {
        name: 'AgreementPersonalData',
        setup() {
            const router = useRouter()
            const route = useRoute()
            const store = useMainStore()

            const updatePersonalDataState = async () => {
                await store.updateUserPersonalDataAgreement()
                router.push('/')
            }

            return {
                router,
                route,
                store,
                updatePersonalDataState
            }
        }
    }
</script>
